import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useLayoutEffect, useState, useMemo } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';

import { sendAzureEvent } from '../helpers/appInsights';
import { RootState } from '../store/store';
import { setAnchorEvent } from '../store/ui/ui.slice';
import { setDashboardMetaData } from '../store/dashboard/dashboard.slice';
import { setInsightId } from '../store/insights/insights.slice';
import { IParams } from '../types';
import UtilityHelper from '../helpers/UtilityHelper';
import SystemHelper from '../helpers/SystemHelper';
import styleGeneral from '../styles/general.module.scss';
import styleGuide from '../styles/styleGuide.module.scss';
import BtnBack from '../components/common/btnBack';
import ConstantsHelper from '../helpers/ConstantsHelper';
import NavigationHelper from '../helpers/NavigationHelper';
import UiHelper from '../helpers/UiHelper';
import DateTimeHelper from '../helpers/DateTimeHelper';
import LoyaltyInsulinUsageBlock from '../components/dashboard/loyalty/loyaltyInsulinUsageBlock';
import LoyaltyInsulinModeUsageChart from '../components/dashboard/loyalty/LoyaltyInsulinModeUsageChart';
import LoyaltyInsulinUsageAvgDaily from '../components/dashboard/loyalty/loyaltyInsulinUsageAvgDaily';
import WeeklyTrend from '../components/weeklyTrend/WeeklyTrend';
import LoyaltyInsulinUsageFactors from '../components/dashboard/loyalty/loyaltyInsulinUsageFactors';

function LoyaltyInsulinUsageInsights() {
    const dispatch = useDispatch();

    const anI18Nextlib = useSelector((state: RootState) => state.app.anI18Nextlib);
    const activeHttpCalls = useSelector((state: RootState) => state.app.activeHttpCalls);
    const profileData = useSelector((state: RootState) => state.profile.data);
    const currentInsulinUsage = useSelector((state: RootState) => state.dashboard.currentInsulinUsage);
    const dashboardBeg = useSelector((state: RootState) => state.dashboard.dashboardBeg);
    const dashboardEnd = useSelector((state: RootState) => state.dashboard.dashboardEnd);
    const dashboardHasReferrer = useSelector((state: RootState) => state.dashboard.dashboardHasReferrer);
    const insightId = useSelector((state: RootState) => state.insights.insightId);
    const insightIds = useSelector((state: RootState) => state.insights.insightIds);
    const cachedInsights = useSelector((state: RootState) => state.insights.cachedInsights);
    const anchorEvent = useSelector((state: RootState) => state.ui.anchorEvent);

    const translate = (key: string, subs?: any) => UiHelper.Translate(anI18Nextlib, key, subs);

    const {
        dashboardBeg: dashboardBegParam,
        dashboardEnd: dashboardEndParam,
        dashboardHasReferrer: dashboardHasReferrerParam,
        insightId: insightIdParam,
    } = useParams<IParams>();

    const handleScroll = (event: any) =>
        NavigationHelper.HandleScroll(
            undefined,
            scrollActive1,
            undefined,
            setScrollActive1,
            undefined,
            event?.target?.scrollTop
        );

    useEffect(() => {
        if (
            !(
                dashboardBeg === dashboardBegParam &&
                dashboardEnd === dashboardEndParam &&
                dashboardHasReferrer === (dashboardHasReferrerParam === 'true')
            )
        ) {
            dispatch(
                setDashboardMetaData({
                    dashboardBeg: dashboardBegParam,
                    dashboardEnd: dashboardEndParam,
                    dashboardHasReferrer: dashboardHasReferrerParam === 'true',
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardBegParam, dashboardEndParam, dashboardHasReferrerParam]);

    useEffect(() => {
        if (insightId !== insightIdParam) {
            dispatch(setInsightId({ insightId: insightIdParam }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insightIdParam]);

    const insight = cachedInsights[insightId];

    const clearAnchorEvent = () => dispatch(setAnchorEvent({ anchorEvent: undefined }));

    const [scrollActive1, setScrollActive1] = useState(false);
    useLayoutEffect(() => {
        SystemHelper.AppInsightsEvent(anI18Nextlib, 'InsightDetails()', 'Mounted');
        sendAzureEvent(ConstantsHelper.analyticsEventNames.VIEW_INSULIN_USAGE_DETAILS, {
            weekOf: dashboardBeg,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (profileData.receivedData && !(dashboardEnd && insightId && insightIds)) {
            const payload = UiHelper.GetInsightsDateRange(
                dispatch,
                insightIds,
                dashboardBeg,
                dashboardEnd,
                dashboardHasReferrer
            );

            if (payload.beg && payload.end) {
                UiHelper.ClearErrors(dispatch);
                UiHelper.FetchDashboard(dispatch, payload.beg, payload.end, activeHttpCalls, profileData);
                UiHelper.FetchDashboardInsulinUsage(dispatch, payload.beg, payload.end, activeHttpCalls, profileData);
                UiHelper.FetchDashboardInsulinUsageTrend(
                    dispatch,
                    payload.beg,
                    payload.end,
                    activeHttpCalls,
                    profileData
                );
                UiHelper.FetchDashboardSummary(dispatch, payload.beg, payload.end, activeHttpCalls, profileData);
                UiHelper.FetchDashboardSummaryGlucoseTrend(
                    dispatch,
                    payload.beg,
                    payload.end,
                    activeHttpCalls,
                    profileData
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dashboardBeg,
        profileData.receivedData,
        profileData?.confidentialityAgreementAccepted,
        profileData?.eulaAccepted,
    ]);

    useEffect(() => {
        if (anchorEvent && insight) {
            NavigationHelper.ScrollIntoView(anchorEvent, clearAnchorEvent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anchorEvent, insight]);

    const shouldDisplayInsulinUsageInformation = useMemo(() => {
        const insulinUsageWeeks = UtilityHelper.ToNumeric(
            SystemHelper.GetRuntimeConfig('REACT_APP_INSULIN_USAGE_WEEKS')
        );
        return currentInsulinUsage?.weeklyTrend?.weeks?.length < insulinUsageWeeks;
    }, [currentInsulinUsage]);

    if (!currentInsulinUsage?.dailyUsage) {
        return null;
    }

    return (
        <div
            data-testid="loyalty-congrats-container"
            className={styleGeneral.body}
            onScroll={(event) => handleScroll(event)}
        >
            <div className={styleGeneral.stickToTopBack}>
                <BtnBack url={NavigationHelper.GenUrlDashboard(dashboardBeg, dashboardEnd, dashboardHasReferrer)} />
            </div>
            <div
                className={clsx(styleGuide.report, styleGuide.solidBgWhite, styleGeneral.stickToTopLevel2)}
                data-testid="loyalty-insulin"
            >
                <div
                    className={clsx(
                        styleGuide.header,
                        styleGuide.solidBgWhite,
                        styleGeneral.dashboardZoomInScroll1OffScroll2Off
                    )}
                >
                    <div className={clsx(styleGuide.loyaltyTitle, scrollActive1 && styleGeneral.inScroll2)}>
                        {translate('loyalty.insulin.title')}
                    </div>
                    <div className={clsx(styleGuide.dateRange, scrollActive1 && styleGuide.dateRangeShort)}>
                        <div className={clsx(styleGuide.caption, scrollActive1 && styleGeneral.inScroll)}>
                            {DateTimeHelper.FormatDateRange(dashboardEnd)}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styleGuide.solidBg} data-testid="loyalty-insulin-frame">
                <div className={clsx(styleGuide.insightsFullWidthFirst, styleGeneral.dashboardSummary)}>
                    <div className={clsx(styleGuide.InsightCard, styleGuide.insightInsulin)}>
                        <div className={styleGuide.whiteBackground}>
                            <LoyaltyInsulinUsageBlock
                                translate={translate}
                                tokenHeader={'loyalty.insulin.weeklyTrend.title'}
                                tokenBody={'loyalty.insulin.weeklyTrend.block'}
                                preSeparate={false}
                            />
                            <WeeklyTrend translate={translate} />
                        </div>
                    </div>
                </div>
                <div className={clsx(styleGuide.insightsFullWidth, styleGeneral.dashboardSummary)}>
                    <div className={clsx(styleGuide.InsightCard, styleGuide.insightInsulin)}>
                        <div className={styleGuide.whiteBackground}>
                            <LoyaltyInsulinUsageBlock
                                translate={translate}
                                tokenHeader={'loyalty.insulin.totalDailyInsulinRatio.title'}
                                tokenInfo={'loyalty.insulin.totalDailyInsulinRatio.info'}
                                tokenBody={
                                    shouldDisplayInsulinUsageInformation
                                        ? 'loyalty.insulin.totalDailyInsulinRatio.totalDailyInsulinBlock'
                                        : ''
                                }
                                weekOf={dashboardBeg}
                            />
                            <LoyaltyInsulinModeUsageChart showUnits />
                        </div>
                    </div>
                </div>
                <div className={clsx(styleGuide.insightsFullWidth, styleGeneral.dashboardSummary)}>
                    <div className={clsx(styleGuide.InsightCard, styleGuide.insightInsulin)}>
                        <div className={styleGuide.whiteBackground}>
                            <div className={styleGuide.header}>
                                <LoyaltyInsulinUsageBlock
                                    translate={translate}
                                    tokenHeader={'loyalty.insulin.avgDailyBolus.title'}
                                    tokenBody={
                                        shouldDisplayInsulinUsageInformation
                                            ? 'loyalty.insulin.avgDailyBolus.block'
                                            : ''
                                    }
                                    preSeparate={false}
                                />
                                <LoyaltyInsulinUsageAvgDaily translate={translate} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LoyaltyInsulinUsageFactors translate={translate} />
        </div>
    );
}

export default LoyaltyInsulinUsageInsights;
