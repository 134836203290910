import { IAppState } from '../../app.types';
import ConstantsHelper from '../../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../../helpers/UtilityHelper';
import UiHelper from '../../../../helpers/UiHelper';
import AnI18NextLibHelper from '../../../../helpers/AnI18NextLibHelper';

const fetchBackEndIdPending = (state: IAppState) => {
    state.loadingSemaphore++;
    const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchBackEndId);
    state.activeHttpCalls[callKey] = true;
    state.versionBackEnd = undefined;
    state.errors[callKey] = UiHelper.CreateErrorMessage({
        level: ConstantsHelper.ErrorLevels.info,
        message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.backEndId'),
    });
};

export default fetchBackEndIdPending;
