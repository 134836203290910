import { useTranslation } from 'react-i18next';
import React from 'react';
import clsx from 'clsx';

import { ICallback } from '../../../types';
import BtnPrimary from '../btnPrimary';
import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import ConstantsHelper from '../../../helpers/ConstantsHelper';
import UiHelper from '../../../helpers/UiHelper';
import welcomeImage from '../../../assets/images/welcome-image.png';
import SystemHelper from '../../../helpers/SystemHelper';

function Welcome({
    closeCb,
}: Readonly<{
    closeCb: ICallback<void>;
}>) {
    const { t } = useTranslation();

    const browserInfo = SystemHelper.GetBrowserInfo();
    const isProduction = SystemHelper.IsProd();

    return (
        <>
            <div
                className={clsx(
                    styleGeneral.layerTopmostLess1,
                    UiHelper.GetBannerSpacingClasses(
                        isProduction,
                        browserInfo.supported,
                        styleGuide.modalBackdrop,
                        styleGuide.modalBackdropOneBanner,
                        styleGuide.modalBackdropTwoBanners
                    )
                )}
            />
            <div className={clsx(styleGeneral.layerTopmostLess1, styleGuide.welcome)}>
                <img src={welcomeImage} className={clsx(styleGuide.icon)} alt={t('welcome.icon')} />
                <div className={styleGuide.title}>{t('welcome.title')}</div>
                <div className={styleGuide.body}>
                    {t('welcome.body', {
                        name: t(ConstantsHelper.NameTag),
                    })}
                </div>
                <BtnPrimary caption={t('welcome.continue')} cbClickFn={() => closeCb()} focus={false} />
            </div>
        </>
    );
}

export default Welcome;
