import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';

import { sendAzureEvent } from '../helpers/appInsights';
import { RootState } from '../store/store';
import { setAnchorEvent } from '../store/ui/ui.slice';
import { setDashboardMetaData } from '../store/dashboard/dashboard.slice';
import { IParams } from '../types';
import SystemHelper from '../helpers/SystemHelper';
import styleGeneral from '../styles/general.module.scss';
import styleGuide from '../styles/styleGuide.module.scss';
import BtnBack from '../components/common/btnBack';
import ConstantsHelper from '../helpers/ConstantsHelper';
import NavigationHelper from '../helpers/NavigationHelper';
import UiHelper from '../helpers/UiHelper';
import DateTimeHelper from '../helpers/DateTimeHelper';
import UtilityHelper from '../helpers/UtilityHelper';
import BtnPrimary from '../components/common/btnPrimary';
import LoyaltyInsulinUsageFactors from '../components/dashboard/loyalty/loyaltyInsulinUsageFactors';

function LoyaltyCongratsInsights() {
    const dispatch = useDispatch();

    const anI18Nextlib = useSelector((state: RootState) => state.app.anI18Nextlib);
    const activeHttpCalls = useSelector((state: RootState) => state.app.activeHttpCalls);
    const profileData = useSelector((state: RootState) => state.profile.data);
    const resource = useSelector((state: RootState) => state.learningMaterial.resource);
    const anchorEvent = useSelector((state: RootState) => state.ui.anchorEvent);
    const dashboardBeg = useSelector((state: RootState) => state.dashboard.dashboardBeg);
    const dashboardEnd = useSelector((state: RootState) => state.dashboard.dashboardEnd);
    const dashboardHasReferrer = useSelector((state: RootState) => state.dashboard.dashboardHasReferrer);
    const insightIds = useSelector((state: RootState) => state.insights.insightIds);

    const translate = (key: string, subs?: any) => UiHelper.Translate(anI18Nextlib, key, subs);

    const {
        dashboardBeg: dashboardBegParam,
        dashboardEnd: dashboardEndParam,
        dashboardHasReferrer: dashboardHasReferrerParam,
    } = useParams<IParams>();

    /* istanbul ignore next: Jest dom doesnt provide actual rendering capability so unable to get scroll values */
    const handleScroll = (event: any) =>
        NavigationHelper.HandleScroll(
            undefined,
            scrollActive1,
            undefined,
            setScrollActive1,
            undefined,
            event?.target?.scrollTop
        );
    const cbLearnMoreClickFn = () => {
        sendAzureEvent(ConstantsHelper.analyticsEventNames.VIEW_OP5_LEARN_MORE, {
            weekOf: dashboardBeg,
        });

        window.open(resource?.data?.linkToMoreResources, '__OP5_LEARN_MORE__', 'noopener,noreferrer');
    };
    const cbResourcesClickFn = () => {
        sendAzureEvent(ConstantsHelper.analyticsEventNames.VIEW_OP5_RESOURCES, {
            weekOf: dashboardBeg,
        });

        window.open(
            SystemHelper.GetRuntimeConfig('REACT_APP_ABOUT_URL_LINK'),
            '__OP5_RESOURCES__',
            'noopener,noreferrer'
        );
    };
    const buttonCaption = resource?.data?.buttonText;

    useEffect(() => {
        if (
            !(
                dashboardBeg === dashboardBegParam &&
                dashboardEnd === dashboardEndParam &&
                dashboardHasReferrer === (dashboardHasReferrerParam === 'true')
            )
        ) {
            dispatch(
                setDashboardMetaData({
                    dashboardBeg: dashboardBegParam,
                    dashboardEnd: dashboardEndParam,
                    dashboardHasReferrer: dashboardHasReferrerParam === 'true',
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardBegParam, dashboardEndParam, dashboardHasReferrerParam]);

    const clearAnchorEvent = () => dispatch(setAnchorEvent({ anchorEvent: undefined }));
    const [scrollActive1, setScrollActive1] = useState(false);

    useLayoutEffect(() => {
        SystemHelper.AppInsightsEvent(anI18Nextlib, 'InsightDetails()', 'Mounted');
        sendAzureEvent(ConstantsHelper.analyticsEventNames.VIEW_OP5_DETAILS, {
            weekOf: dashboardBeg,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (profileData.receivedData && !(dashboardEnd && resource?.data?.weekNumber)) {
            const payload = UiHelper.GetInsightsDateRange(
                dispatch,
                insightIds,
                dashboardBeg,
                dashboardEnd,
                dashboardHasReferrer
            );

            if (payload.beg && payload.end) {
                UiHelper.ClearErrors(dispatch);
                UiHelper.FetchDashboard(dispatch, payload.beg, payload.end, activeHttpCalls, profileData);
                UiHelper.FetchLearningMaterialOp5Progress(
                    dispatch,
                    payload.beg,
                    payload.end,
                    activeHttpCalls,
                    profileData
                );
                UiHelper.FetchDashboardInsulinUsage(dispatch, payload.beg, payload.end, activeHttpCalls, profileData);
                UiHelper.FetchDashboardInsulinUsageTrend(
                    dispatch,
                    payload.beg,
                    payload.end,
                    activeHttpCalls,
                    profileData
                );
                UiHelper.FetchDashboardSummary(dispatch, payload.beg, payload.end, activeHttpCalls, profileData);
                UiHelper.FetchDashboardSummaryGlucoseTrend(
                    dispatch,
                    payload.beg,
                    payload.end,
                    activeHttpCalls,
                    profileData
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dashboardEnd,
        profileData.receivedData,
        profileData?.confidentialityAgreementAccepted,
        profileData?.eulaAccepted,
    ]);

    useEffect(() => {
        if (anchorEvent && resource?.data?.weekNumber) {
            NavigationHelper.ScrollIntoView(anchorEvent, clearAnchorEvent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anchorEvent, resource?.data?.weekNumber]);

    // TODO:  Add tests for this effect under tech debt work
    useEffect(() => {
        if (
            !UtilityHelper.IsNull(dashboardBeg) &&
            !UtilityHelper.IsNull(dashboardEnd) &&
            (dashboardEnd !== resource.id || DateTimeHelper.GetIsoNow() > resource.expires)
        ) {
            UiHelper.FetchLearningMaterialResources(dispatch, dashboardBeg, dashboardEnd, activeHttpCalls, profileData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardBeg, dashboardEnd, profileData?.confidentialityAgreementAccepted, profileData?.eulaAccepted]);

    if (!resource?.data?.weekNumber) {
        return null;
    }

    return (
        <div
            data-testid="loyalty-congrats-container"
            className={styleGeneral.body}
            onScroll={(event) => {
                handleScroll(event);
            }}
        >
            <div className={styleGeneral.stickToTopBack}>
                <BtnBack url={NavigationHelper.GenUrlDashboard(dashboardBeg, dashboardEnd, dashboardHasReferrer)} />
            </div>
            <div
                className={clsx(styleGuide.report, styleGuide.solidBgWhite, styleGeneral.stickToTopLevel2)}
                data-testid="loyalty-congrats"
            >
                <div
                    className={clsx(
                        styleGuide.header,
                        styleGuide.solidBgWhite,
                        styleGeneral.dashboardZoomInScroll1OffScroll2Off
                    )}
                >
                    <div className={clsx(styleGuide.loyaltyTitle, scrollActive1 && styleGeneral.inScroll2)}>
                        {translate('loyalty.congrats.weekTitle', {
                            weekNumber: resource?.data?.weekNumber,
                        })}
                    </div>
                    <div className={clsx(styleGuide.dateRange, scrollActive1 && styleGuide.dateRangeShort)}>
                        {dashboardEnd && (
                            <div
                                className={clsx(styleGuide.caption, scrollActive1 && styleGeneral.inScroll)}
                                data-testid="loyalty-congrats-dashboardEnd"
                            >
                                {DateTimeHelper.FormatDateRange(dashboardEnd)}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={styleGuide.solidBg} data-testid="loyalty-congrats-frame">
                <div className={clsx(styleGuide.insightsFullWidth, styleGeneral.dashboardSummary)}>
                    <div className={styleGuide.InsightCard}>
                        <div className={styleGuide.whiteBackground}>
                            <div className={styleGuide.header}>
                                <div className={clsx(styleGeneral.table, styleGuide.topGutter, styleGuide.linkCursor)}>
                                    <Tooltip title={buttonCaption}>
                                        <img
                                            src={resource?.data?.featuredImage}
                                            className={clsx(styleGuide.blockWidth)}
                                            alt={translate('loyalty.congrats.therapy.imgText')}
                                            onClick={cbLearnMoreClickFn}
                                        />
                                    </Tooltip>
                                </div>
                                <div className={styleGuide.loyaltyHeader}>
                                    <div className={styleGuide.titleAlt}>{resource?.data?.title}</div>
                                </div>
                                <div className={styleGuide.loyaltyBody}>
                                    <div className={styleGuide.text}>{resource?.data?.body}</div>
                                </div>
                            </div>
                            <BtnPrimary caption={buttonCaption} cbClickFn={cbLearnMoreClickFn} />
                            <div className={styleGuide.header}>
                                <div className={clsx(styleGuide.lineAlt2)}></div>
                                <div className={styleGuide.loyaltyFooter}>
                                    <div className={styleGuide.textAlt}>
                                        {translate('loyalty.congrats.therapy.read')}
                                        <div className={clsx(styleGeneral.link)} onClick={cbResourcesClickFn}>
                                            {translate('loyalty.congrats.therapy.resourcesA')}
                                            <sup>&#174;</sup>&nbsp;
                                            {translate('loyalty.congrats.therapy.resourcesB')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LoyaltyInsulinUsageFactors translate={translate} warningsOnly />
        </div>
    );
}

export default LoyaltyCongratsInsights;
