import { PayloadAction } from '@reduxjs/toolkit';

import { IDashboardState } from './dashboard.types';
import { dashboardInitialState } from './dashboard.initialState';

const dashboardReducers = {
    setDashboardMetaData: {
        reducer: (
            state: IDashboardState,
            action: PayloadAction<{
                dashboardBeg: string;
                dashboardEnd: string;
                dashboardHasReferrer?: boolean;
            }>
        ) => {
            state.dashboardBeg = action.payload.dashboardBeg;
            state.dashboardEnd = action.payload.dashboardEnd;
            state.dashboardHasReferrer = action.payload.dashboardHasReferrer;
            state.currentSummary = dashboardInitialState.currentSummary;
            state.currentSummaryGlucoseTrend = dashboardInitialState.currentSummaryGlucoseTrend;
        },
        prepare: ({
            dashboardBeg,
            dashboardEnd,
            dashboardHasReferrer,
        }: {
            dashboardBeg: string;
            dashboardEnd: string;
            dashboardHasReferrer?: boolean;
        }) => ({
            payload: {
                dashboardBeg,
                dashboardEnd,
                dashboardHasReferrer,
            },
        }),
    },
};

export default dashboardReducers;
