import { useTranslation } from 'react-i18next';
import React from 'react';
import clsx from 'clsx';
import { ResponsiveBar } from '@nivo/bar';

import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import ConstantsHelper from '../../../helpers/ConstantsHelper';
import { IInsight3 } from '../../../types';

function InsightSummaryTimesInRange({ summary }: Readonly<{ summary: IInsight3 }>) {
    const { t } = useTranslation();

    const getChartSettings = (low: number, normal: number, high: number) => {
        const dataPoint: any = {};

        dataPoint[ConstantsHelper.DataSeriesLow] = low;
        dataPoint[ConstantsHelper.DataSeriesNormal] = normal;
        dataPoint[ConstantsHelper.DataSeriesHigh] = high;

        return {
            height: 20,
            margin: { top: 0, right: 0, bottom: 0, left: 0 },
            indexBy: 'index',
            padding: 0.15,
            enableLabel: false,
            innerPadding: 1,
            borderRadius: 8,
            labelTextColor: 'inherit:darker(1.4)',
            labelSkipWidth: 16,
            labelSkipHeight: 16,
            layout: 'horizontal',
            enableGridY: false,
            enableGridX: false,
            animate: true,
            areaOpacity: 0.15,
            axisBottom: null,
            axisLeft: null,
            axisRight: null,
            axisTop: null,
            colors: ['#f75e4c', '#2bda7b', '#ffa700'],
            data: [dataPoint],
            enablePointLabel: false,
            tooltip: () => <></>,
            keys: [ConstantsHelper.DataSeriesLow, ConstantsHelper.DataSeriesNormal, ConstantsHelper.DataSeriesHigh],
            xScale: null,
            yScale: null,
        } as any;
    };

    return (
        <div className={clsx(styleGeneral.main, styleGuide.insightSummary)}>
            <div className={styleGuide.whiteBackground}>
                <div className={styleGuide.headerSummary}>
                    <div className={styleGuide.timeInRangeSummary}>
                        <div className={clsx(styleGeneral.table, styleGuide.row1)}>
                            <div className={clsx(styleGeneral.row)}>
                                <span className={clsx(styleGeneral.cell, styleGuide.left)}>
                                    <span className={clsx(styleGeneral.cell, styleGuide.top)}>
                                        <div className={styleGuide.titleAlt}>
                                            <div>{t('timeInRange')}</div>
                                        </div>
                                        <div className={styleGuide.subTitle}>
                                            {t('targetRangeFromTo', {
                                                from: ConstantsHelper.ReadingsGoodEgvMin,
                                                to: ConstantsHelper.ReadingsGoodEgvMax,
                                                units: ConstantsHelper.ReadingsUnit,
                                            })}
                                        </div>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className={clsx(styleGeneral.table, styleGuide.row2)}>
                            <div className={styleGeneral.row}>
                                <span className={clsx(styleGeneral.cell, styleGuide.left, styleGuide.dayBoxGreen2)}>
                                    {summary.timeInRangePct}%
                                </span>
                                <span className={clsx(styleGeneral.cell, styleGuide.right)}>
                                    <span className={styleGuide.middle}>{t('inRange2')}</span>
                                </span>
                            </div>
                        </div>
                        <div className={clsx(styleGeneral.table, styleGuide.row3)}>
                            <div className={styleGeneral.row}>
                                <span
                                    className={clsx(styleGeneral.cell, styleGuide.chart)}
                                    data-testid={'timesInRangeTestId'}
                                >
                                    <ResponsiveBar
                                        {...getChartSettings(
                                            summary.timeInRangeLowPct,
                                            summary.timeInRangePct,
                                            summary.timeInRangeHighPct
                                        )}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className={clsx(styleGeneral.table, styleGuide.row4)}>
                            <div className={styleGeneral.row}>
                                <span className={clsx(styleGeneral.cell, styleGuide.left)}>
                                    <span className={clsx(styleGuide.caption)}>{t('low')}</span>
                                    <span className={clsx(styleGuide.value, styleGuide.low)}>
                                        {summary.timeInRangeLowPct}%
                                    </span>
                                </span>
                                <span className={clsx(styleGeneral.cell, styleGuide.right)}>
                                    <span className={clsx(styleGuide.caption)}>{t('high')}</span>
                                    <span className={clsx(styleGuide.value, styleGuide.high)}>
                                        {summary.timeInRangeHighPct}%
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InsightSummaryTimesInRange;
