import { IDashboardState } from './dashboard.types';

export const dashboardInitialState: IDashboardState = {
    dashboardBeg: undefined,
    dashboardEnd: undefined,
    dashboardHasReferrer: false,
    currentInsulinUsage: {},
    currentSummary: {},
    currentSummaryGlucoseTrend: {},
};
