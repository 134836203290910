import { useOktaAuth } from '@okta/okta-react';
import React from 'react';

import Menu from './Menu';

import styleGeneral from '../../styles/general.module.scss';
import MenuHelper from '../../helpers/MenuHelper';
import '../../app/App.scss';
import 'bootstrap/dist/css/bootstrap.css'; // Import precompiled Bootstrap css

function Header() {
    const authState = useOktaAuth()?.authState;
    const menuEntries = MenuHelper.MenuEntries.filter(
        (e) =>
            (authState?.isAuthenticated && !e.hidden && !e.disabled) ||
            (!authState?.isAuthenticated && e.key === MenuHelper.MenuEntrySignIn.key)
    );

    return (
        <div className={styleGeneral.header}>
            <Menu menuEntries={menuEntries} />
        </div>
    );
}

export default Header;
