import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';

import { handleApplicationInsightsConfigurationUpdateOnStoreChange } from './helpers/appInsights';
import App from './app/App';
import store from './store/store';
import theme from './styles/theme';
import './index.css';
import './i18n/index';

store.subscribe(() => handleApplicationInsightsConfigurationUpdateOnStoreChange(store.getState()));

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <Router>
                    <App />
                </Router>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('react-root') || document.createElement('div')
);
