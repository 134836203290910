import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { sendAzureEvent } from '../helpers/appInsights';
import { RootState } from '../store/store';
import { setDashboardMetaData } from '../store/dashboard/dashboard.slice';
import { setContributingEventMetaData, setInsightId } from '../store/insights/insights.slice';
import { IParams } from '../types';
import SystemHelper from '../helpers/SystemHelper';
import ContributingEventDetail from '../components/contributingEvent/ContributingEventDetail';
import styleGeneral from '../styles/general.module.scss';
import BtnBack from '../components/common/btnBack';
import UiHelper from '../helpers/UiHelper';
import NavigationHelper from '../helpers/NavigationHelper';
import ConstantsHelper from '../helpers/ConstantsHelper';
import InsightHelper from '../helpers/InsightHelper';

function ContributingEvent() {
    const dispatch = useDispatch();

    const anI18Nextlib = useSelector((state: RootState) => state.app.anI18Nextlib);
    const activeHttpCalls = useSelector((state: RootState) => state.app.activeHttpCalls);
    const profileData = useSelector((state: RootState) => state.profile.data);
    const windowSize = useSelector((state: RootState) => state.ui.windowSize);
    const dashboardHasReferrer = useSelector((state: RootState) => state.dashboard.dashboardHasReferrer);
    const dashboardBeg = useSelector((state: RootState) => state.dashboard.dashboardBeg);
    const dashboardEnd = useSelector((state: RootState) => state.dashboard.dashboardEnd);
    const insightId = useSelector((state: RootState) => state.insights.insightId);
    const insightIds = useSelector((state: RootState) => state.insights.insightIds);
    const contributingEventId = useSelector((state: RootState) => state.insights.contributingEventId);
    const contributingEventBeg = useSelector((state: RootState) => state.insights.contributingEventBeg);
    const cachedInsights = useSelector((state: RootState) => state.insights.cachedInsights);
    const currentReadingCombo = useSelector((state: RootState) => state.insights.currentReadingCombo);

    const translate = (key: string, subs?: any) => UiHelper.Translate(anI18Nextlib, key, subs);

    const {
        dashboardBeg: dashboardBegParam,
        dashboardEnd: dashboardEndParam,
        dashboardHasReferrer: dashboardHasReferrerParam,
        insightId: insightIdParam,
        contributingEventId: contributingEventIdParam,
        contributingEventBeg: contributingEventBegParam,
        contributingEventEnd: contributingEventEndParam,
    } = useParams<IParams>();

    /* istanbul ignore next: Jest dom doesnt provide actual rendering capability so unable to get scroll values */
    const handleScroll = (event: any) =>
        NavigationHelper.HandleScroll(
            undefined,
            scrollActive1,
            undefined,
            setScrollActive1,
            undefined,
            event?.target?.scrollTop
        );

    useEffect(() => {
        if (
            !(
                dashboardBeg === dashboardBegParam &&
                dashboardEnd === dashboardEndParam &&
                dashboardHasReferrer === (dashboardHasReferrerParam === 'true')
            )
        ) {
            dispatch(
                setDashboardMetaData({
                    dashboardBeg: dashboardBegParam,
                    dashboardEnd: dashboardEndParam,
                    dashboardHasReferrer: dashboardHasReferrerParam === 'true',
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardBegParam, dashboardEndParam, dashboardHasReferrerParam]);

    useEffect(() => {
        if (insightId !== insightIdParam) {
            dispatch(setInsightId({ insightId: insightIdParam }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insightIdParam]);

    useEffect(() => {
        if (
            !(
                contributingEventId === contributingEventIdParam &&
                contributingEventBeg === contributingEventBegParam &&
                contributingEventBeg === contributingEventEndParam
            )
        ) {
            dispatch(
                setContributingEventMetaData({
                    contributingEventId: contributingEventIdParam,
                    contributingEventBeg: contributingEventBegParam,
                    contributingEventEnd: contributingEventEndParam,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contributingEventIdParam, contributingEventBegParam, contributingEventEndParam]);

    const insight = cachedInsights[InsightHelper.ConvertInsightIdSingleToChildAndParent(insightId).child];
    const insightEvent = insight?.windowEgvEvent?.find((e) => e.eventId === contributingEventId);
    const dataItemReadings = currentReadingCombo;
    const [scrollActive1, setScrollActive1] = useState(false);

    useEffect(() => {
        SystemHelper.AppInsightsEvent(anI18Nextlib, 'ContributingEvent()', 'Mounted');
        if (insightEvent?.eventId && !currentReadingCombo?.fetched) {
            sendAzureEvent(ConstantsHelper.analyticsEventNames.VIEW_EVENT_DETAILS, {
                eventId: contributingEventId,
            });
            UiHelper.FetchInsightEventReadings(
                dispatch,
                insightIdParam,
                insightEvent?.eventId,
                activeHttpCalls,
                profileData
            );
            UiHelper.FetchInsightEventBoluses(
                dispatch,
                insightIdParam,
                insightEvent?.eventId,
                activeHttpCalls,
                profileData
            );
            UiHelper.FetchInsightEventModes(
                dispatch,
                insightIdParam,
                insightEvent?.eventId,
                activeHttpCalls,
                profileData
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        anI18Nextlib,
        insightEvent,
        insightIdParam,
        profileData?.confidentialityAgreementAccepted,
        profileData?.eulaAccepted,
    ]);

    useEffect(() => {
        if (
            profileData.receivedData &&
            !(
                dashboardEnd &&
                InsightHelper.ConvertInsightIdSingleToChildAndParent(insightId).child.length &&
                insightIds
            )
        ) {
            const payload = UiHelper.GetInsightsDateRange(
                dispatch,
                insightIds,
                dashboardBeg,
                dashboardEnd,
                dashboardHasReferrer
            );

            if (payload.beg && payload.end) {
                UiHelper.ClearErrors(dispatch);
                UiHelper.FetchDashboard(dispatch, payload.beg, payload.end, activeHttpCalls, profileData);
                UiHelper.FetchDashboardInsulinUsage(dispatch, payload.beg, payload.end, activeHttpCalls, profileData);
                UiHelper.FetchDashboardInsulinUsageTrend(
                    dispatch,
                    payload.beg,
                    payload.end,
                    activeHttpCalls,
                    profileData
                );
                UiHelper.FetchDashboardSummary(dispatch, payload.beg, payload.end, activeHttpCalls, profileData);
                UiHelper.FetchDashboardSummaryGlucoseTrend(
                    dispatch,
                    payload.beg,
                    payload.end,
                    activeHttpCalls,
                    profileData
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dashboardEnd,
        profileData.receivedData,
        profileData?.confidentialityAgreementAccepted,
        profileData?.eulaAccepted,
    ]);

    if (insight) {
        if (windowSize.width !== 0 && windowSize.height !== 0) {
            SystemHelper.AppInsightsInfo(
                anI18Nextlib,
                'Dashboard',
                'Debug',
                `New size is ${windowSize.width}x${windowSize.height}`
            );
        }
    }

    if (!insight) {
        return null;
    }

    return (
        <div
            className={styleGeneral.body}
            onScroll={
                /* istanbul ignore next: Jest dom doesnt provide actual rendering capability so unable to get scroll values */ (
                    event
                ) => handleScroll(event)
            }
        >
            <div className={styleGeneral.stickToTopBack}>
                <BtnBack
                    url={NavigationHelper.GenUrlInsight(
                        dashboardBeg,
                        dashboardEnd,
                        dashboardHasReferrer,
                        InsightHelper.ConvertInsightIdSingleToChildAndParent(insightId).parent
                    )}
                />
                {/* {newSize.w}x{newSize.h} */}
            </div>
            <div className={styleGeneral.insightEntryDetail}>
                <ContributingEventDetail
                    dispatch={dispatch}
                    translate={translate}
                    scrollActive1={scrollActive1}
                    insight={insight}
                    contributingEvent={insightEvent}
                    dataItemReadings={dataItemReadings}
                />
            </div>
        </div>
    );
}

export default ContributingEvent;
