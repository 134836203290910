import { Middleware } from '@reduxjs/toolkit';

import { resetInsightIds, resetCurrentReadingCombo, resetCachedInsights } from './insights/insights.slice';
import { setDashboardMetaData } from './dashboard/dashboard.slice';

const dashboardMetaDataMiddleware: Middleware = (store) => (next) => (action) => {
    if (setDashboardMetaData.match(action)) {
        next(action);

        store.dispatch(resetInsightIds());
        store.dispatch(resetCurrentReadingCombo());
        store.dispatch(resetCachedInsights());
    } else {
        return next(action);
    }
};

export default dashboardMetaDataMiddleware;
