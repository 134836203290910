import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import React, { memo, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { sendAzureEvent } from 'src/helpers/appInsights';

import { setMessage } from '../store/app/app.slice';
import { setValuesFromStateInLocalStorage } from '../helpers/SessionHelper';
import {
    setAnchorInsight,
    setAnchorLoyaltyCongrats,
    setAnchorLoyaltyInsulin,
    setCarouselIndex,
} from '../store/ui/ui.slice';
import { RootState } from '../store/store';
import { IPatient } from '../store/profile/profile.types';
import { setDashboardMetaData } from '../store/dashboard/dashboard.slice';
import { setInsightId } from '../store/insights/insights.slice';
import { IParams, IObjectHash } from '../types';
import BtnBack from '../components/common/btnBack';
import SystemHelper from '../helpers/SystemHelper';
import DateTimeHelper from '../helpers/DateTimeHelper';
import NavigationHelper from '../helpers/NavigationHelper';
import styleGeneral from '../styles/general.module.scss';
import styleGuide from '../styles/styleGuide.module.scss';
import InsightsFrame from '../components/dashboard/insightsFrame';
import UiHelper from '../helpers/UiHelper';
import ConstantsHelper from '../helpers/ConstantsHelper';
import UtilityHelper from '../helpers/UtilityHelper';

const getInsightInDateRange = (
    dispatch: any,
    activeHttpCalls: IObjectHash<boolean>,
    insightIds: string[],
    dashboardBeg: string,
    dashboardEnd: string,
    beg: string,
    end: string,
    dashboardHasReferrerInsight: boolean,
    profileData: IPatient
) => {
    if (
        !(dashboardBeg === beg && dashboardEnd === end) ||
        dashboardHasReferrerInsight ||
        (dashboardBeg === beg && dashboardEnd === end && !insightIds)
    ) {
        const payload = UiHelper.GetInsightsDateRange(
            dispatch,
            insightIds,
            dashboardBeg,
            dashboardEnd,
            dashboardHasReferrerInsight,
            beg,
            end
        );

        if (payload.beg && payload.end) {
            UiHelper.FetchDashboard(dispatch, payload.beg, payload.end, activeHttpCalls, profileData);
            UiHelper.FetchDashboardInsulinUsage(dispatch, payload.beg, payload.end, activeHttpCalls, profileData);
            UiHelper.FetchDashboardInsulinUsageTrend(dispatch, payload.beg, payload.end, activeHttpCalls, profileData);
            UiHelper.FetchDashboardSummary(dispatch, payload.beg, payload.end, activeHttpCalls, profileData);
            UiHelper.FetchDashboardSummaryGlucoseTrend(
                dispatch,
                payload.beg,
                payload.end,
                activeHttpCalls,
                profileData
            );
        }
    }
};

const armInsightEvents = (
    dispatch: any,
    insightId: string,
    { insightId: insightIdParam, anchorInsight, patternType, insightType }: any
): void => {
    sendAzureEvent(ConstantsHelper.analyticsEventNames.CLICK_INSIGHT_DETAILS, {
        insightId,
        insightType,
        patternType,
    });

    dispatch(setInsightId({ insightId: insightIdParam }));
    dispatch(setMessage({ message: undefined }));
    dispatch(setAnchorInsight({ anchorInsight }));

    setValuesFromStateInLocalStorage({ [ConstantsHelper.IntersessionKeys.insightId]: insightId });
};

const cbCarouselSetIndexFn = (dispatch: any, carouselIndex: number, { index }: { index: number }) => {
    // Note:
    //     There seems to be bug in the carousel where double-clicking on the selector arrows will
    //     keep firing index changed event where both old and new indices are equal.
    //     This results in an infinite loop that is broken with this conditional check
    if (carouselIndex !== index) {
        dispatch(setCarouselIndex({ index }));
    }
};

function Insights() {
    const dispatch = useDispatch();

    const currentSummary = useSelector((state: RootState) => state.dashboard.currentSummary);
    const currentSummaryGlucoseTrend = useSelector((state: RootState) => state.dashboard.currentSummaryGlucoseTrend);
    const dashboardEnd = useSelector((state: RootState) => state.dashboard.dashboardEnd);
    const dashboardHasReferrer = useSelector((state: RootState) => state.dashboard.dashboardHasReferrer);
    const carouselIndex = useSelector((state: RootState) => state.ui.carouselIndex);
    const anchorInsight = useSelector((state: RootState) => state.ui.anchorInsight);
    const anchorLoyaltyCongrats = useSelector((state: RootState) => state.ui.anchorLoyaltyCongrats);
    const anchorLoyaltyInsulin = useSelector((state: RootState) => state.ui.anchorLoyaltyInsulin);
    const insightId = useSelector((state: RootState) => state.insights.insightId);
    const insightIds = useSelector((state: RootState) => state.insights.insightIds);
    const profileData = useSelector((state: RootState) => state.profile.data);

    const [scrollActive1, setScrollActive1] = useState(false);
    const [scrollActive2, setScrollActive2] = useState(false);

    const handleScroll = useCallback(
        (event: any) =>
            NavigationHelper.HandleScroll(
                12,
                scrollActive1,
                scrollActive2,
                setScrollActive1,
                setScrollActive2,
                event?.target?.scrollTop
            ),
        [scrollActive1, scrollActive2]
    );

    useEffect(() => {
        if (anchorInsight && insightIds) {
            NavigationHelper.ScrollIntoView(anchorInsight, () =>
                dispatch(setAnchorInsight({ anchorInsight: undefined }))
            );
        }
    }, [dispatch, anchorInsight, insightIds]);

    useEffect(() => {
        if (anchorLoyaltyCongrats && insightIds) {
            NavigationHelper.ScrollIntoView(anchorLoyaltyCongrats, () =>
                dispatch(setAnchorLoyaltyCongrats({ anchorLoyaltyCongrats }))
            );
        }
    }, [dispatch, anchorLoyaltyCongrats, insightIds]);

    useEffect(() => {
        if (anchorLoyaltyInsulin && insightIds) {
            NavigationHelper.ScrollIntoView(anchorLoyaltyInsulin, () =>
                dispatch(setAnchorLoyaltyInsulin({ anchorLoyaltyInsulin }))
            );
        }
    }, [dispatch, anchorLoyaltyInsulin, insightIds]);

    const pwdName = useMemo(
        () => UtilityHelper.GetFullName(profileData?.firstName, profileData?.lastName),
        [profileData?.firstName, profileData?.lastName]
    );

    return (
        <div className={styleGeneral.body} onScroll={(event) => handleScroll(event)}>
            {dashboardHasReferrer && (
                <div className={styleGeneral.stickToTopBack}>
                    <BtnBack url="/all-reports" />
                </div>
            )}
            <div
                className={clsx(
                    styleGuide.report,
                    styleGuide.solidBgWhite,
                    dashboardHasReferrer ? styleGeneral.stickToTopLevel2 : styleGeneral.stickToTopLevel1
                )}
                data-testid="insights"
            >
                <div
                    className={clsx(
                        styleGuide.header,
                        styleGuide.solidBgWhite,
                        styleGeneral.dashboardZoomInScroll1OffScroll2Off
                    )}
                >
                    <div
                        className={clsx(
                            dashboardHasReferrer ? styleGuide.patientNameAlt : styleGuide.patientName,
                            scrollActive1 && styleGeneral.inScroll2
                        )}
                    >
                        {pwdName.length > 0 ? pwdName : '--'}
                    </div>
                    <div className={clsx(styleGuide.dateRange, scrollActive1 && styleGuide.dateRangeShort)}>
                        <div className={clsx(styleGuide.caption, scrollActive1 && styleGeneral.inScroll)}>
                            {DateTimeHelper.FormatDateRange(dashboardEnd)}
                        </div>
                    </div>
                </div>
            </div>

            <InsightsFrame
                scrollActive1={scrollActive1}
                scrollActive2={scrollActive2}
                insightIds={insightIds}
                summary={currentSummary}
                summaryGlucoseTrend={currentSummaryGlucoseTrend}
                cbClickFn={(clickParams: any) => armInsightEvents(dispatch, insightId, clickParams)}
                cbCarouselSetIndexFn={(setIndexParams: any) =>
                    cbCarouselSetIndexFn(dispatch, carouselIndex, setIndexParams)
                }
                cbLoyaltyCongrats={(anchor: string) =>
                    dispatch(setAnchorLoyaltyCongrats({ anchorLoyaltyCongrats: anchor }))
                }
                cbLoyaltyInsulin={(anchor: string) =>
                    dispatch(setAnchorLoyaltyInsulin({ anchorLoyaltyInsulin: anchor }))
                }
            />
        </div>
    );
}

const Dashboard = memo(function Dashboard() {
    const dispatch = useDispatch();

    const anI18Nextlib = useSelector((state: RootState) => state.app.anI18Nextlib);
    const activeHttpCalls = useSelector((state: RootState) => state.app.activeHttpCalls);
    const allLoaded = useSelector((state: RootState) => state.app.allLoaded);
    const profileData = useSelector((state: RootState) => state.profile.data);
    const oktaData = useSelector((state: RootState) => state.authentication.oktaData);
    const op5Progress = useSelector((state: RootState) => state.learningMaterial.op5Progress);
    const insightIds = useSelector((state: RootState) => state.insights.insightIds);
    const dashboardHasReferrer = useSelector((state: RootState) => state.dashboard.dashboardHasReferrer);
    const dashboardBeg = useSelector((state: RootState) => state.dashboard.dashboardBeg);
    const dashboardEnd = useSelector((state: RootState) => state.dashboard.dashboardEnd);

    const {
        dashboardBeg: dashboardBegParam,
        dashboardEnd: dashboardEndParam,
        dashboardHasReferrer: dashboardHasReferrerParam,
    } = useParams<IParams>();

    useEffect(() => {
        if (
            allLoaded &&
            dashboardBegParam &&
            dashboardEndParam &&
            !(
                dashboardBeg === dashboardBegParam &&
                dashboardEnd === dashboardEndParam &&
                dashboardHasReferrer === (dashboardHasReferrerParam === 'true')
            )
        ) {
            dispatch(
                setDashboardMetaData({
                    dashboardBeg: dashboardBegParam,
                    dashboardEnd: dashboardEndParam,
                    dashboardHasReferrer: dashboardHasReferrerParam === 'true',
                })
            );
        }
    }, [allLoaded, dashboardBeg, dashboardBegParam, dashboardEnd, dashboardEndParam, dashboardHasReferrer, dashboardHasReferrerParam, dispatch]);

    useEffect(() => {
        const isDashboardBegStateEqualToItsParam = dashboardBeg === dashboardBegParam;
        const isDashboardEndStateEqualToItsParam = dashboardEnd === dashboardEndParam;
        const isDashboardBegAndEndSameAsTheirParams =
            isDashboardBegStateEqualToItsParam && isDashboardEndStateEqualToItsParam;

        if (allLoaded && profileData.receivedData && !insightIds && isDashboardBegAndEndSameAsTheirParams) {
            const hasReferrer = UtilityHelper.IsNull(dashboardHasReferrerParam)
                ? dashboardHasReferrer
                : dashboardHasReferrerParam === 'true';
            const dashboardEndFallback = dashboardEnd ?? DateTimeHelper.GetDateNearestPastSaturday(dashboardEnd, 6);

            let dateRangeCurr = DateTimeHelper.GetIsoWeekRange(dashboardEndFallback, 0);

            if (!dateRangeCurr.end) {
                dateRangeCurr = DateTimeHelper.GetIsoWeekRange(
                    DateTimeHelper.GetDateNearestPastSaturday(dashboardEndFallback, 0),
                    0
                );
            }

            getInsightInDateRange(
                dispatch,
                activeHttpCalls,
                insightIds,
                dashboardBeg,
                dashboardEnd,
                dateRangeCurr.beg,
                dateRangeCurr.end,
                hasReferrer,
                profileData
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, dashboardEnd, allLoaded, profileData]);

    // TODO:  Add tests for this effect under tech debt work
    useEffect(() => {
        if (
            !UtilityHelper.IsNull(dashboardBeg) &&
            !UtilityHelper.IsNull(dashboardEnd) &&
            (dashboardEnd !== op5Progress.id || DateTimeHelper.GetIsoNow() > op5Progress.expires)
        ) {
            UiHelper.FetchLearningMaterialOp5Progress(
                dispatch,
                dashboardBeg,
                dashboardEnd,
                activeHttpCalls,
                profileData
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardBeg, dashboardEnd, profileData]);

    useLayoutEffect(() => {
        if (oktaData.userName) {
            SystemHelper.AppInsightsEvent(anI18Nextlib, 'Dashboard()', 'Mounted');
            sendAzureEvent(ConstantsHelper.analyticsEventNames.VIEW_DASHBOARD);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (UtilityHelper.IsNull(insightIds)) {
        return null;
    }

    return <Insights />;
});

export default Dashboard;
