import { IAppState } from './app.types';

import AnI18NextLibHelper from '../../helpers/AnI18NextLibHelper';
import ConstantsHelper from '../../helpers/ConstantsHelper';

export const appInitialState: IAppState = {
    activeHttpCalls: {},
    allLoaded: false,
    allReportsRowCnt: 0,
    anI18Nextlib: AnI18NextLibHelper.loadLang('en'),
    calendarDate: undefined,
    endpointPdfUrlPrefix: undefined,
    endpointPwdUrlPrefix: undefined,
    errors: {
        general: {
            level: ConstantsHelper.ErrorLevels.clear,
            message: '',
            title: '',
        },
    },
    hcpAppUrl: undefined,
    idTag: '20230507',
    isPolling: false,
    loadingSemaphore: 0,
    message: undefined,
    reloadInProgress: undefined,
    signInUrl: undefined,
    versionBackEnd: undefined,
};
