import { IInsightsState } from '../../insights.types';

const sendFeedbackPending = (state: IInsightsState, action: any) => {
    const insight = state.cachedInsights[action.meta.arg.insightId];

    if (insight) {
        insight.feedbackValue = action.meta.arg.feedbackValue;
    }
};

export default sendFeedbackPending;
