import { useSelector } from 'react-redux';
import React from 'react';

import { ITranslator } from '../../../types';
import { RootState } from '../../../store/store';
import InsightEdNotes from '../../../components/insight/InsightEdNotes';
import InsightEdWarning from '../../../components/insight/InsightEdWarning';
import UtilityHelper from '../../../helpers/UtilityHelper';

function LoyaltyInsulinUsageFactors({
    translate,
    warningsOnly,
}: Readonly<{
    translate: ITranslator;
    warningsOnly?: boolean;
}>) {
    const currentInsulinUsage = useSelector((state: RootState) => state.dashboard.currentInsulinUsage);

    const edNotes = UtilityHelper.GetEdNotes(
        translate,
        null,
        currentInsulinUsage?.displayUsageExplanation?.factorsToConsider
    );

    return (
        <>
            {warningsOnly || <InsightEdNotes notes={edNotes} translate={translate} />}
            <InsightEdWarning notes={edNotes} translate={translate} />
        </>
    );
}

export default LoyaltyInsulinUsageFactors;
