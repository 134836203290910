import { IAppState } from '../../app.types';
import ConstantsHelper from '../../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../../helpers/UtilityHelper';
import UiHelper from '../../../../helpers/UiHelper';
import AnI18NextLibHelper from '../../../../helpers/AnI18NextLibHelper';

const fetchLearningMaterialResourcesFulfilled = (state: IAppState, action: any) => {
    const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchLearningMaterialResources, [
        action.meta?.arg?.beg,
        action.meta?.arg?.end,
    ]);

    if (callKey) {
        delete state.activeHttpCalls[callKey];
    }

    if (action.payload) {
        state.errors[callKey] = UiHelper.CreateErrorMessage({
            level: ConstantsHelper.ErrorLevels.success,
            message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
            title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.failedLearningMaterialResource'),
        });
    }

    state.loadingSemaphore--;
};

export default fetchLearningMaterialResourcesFulfilled;
