import { IAppState } from '../../app.types';
import ConstantsHelper from '../../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../../helpers/UtilityHelper';
import UiHelper from '../../../../helpers/UiHelper';
import AnI18NextLibHelper from '../../../../helpers/AnI18NextLibHelper';

const fetchInsightEventReadingsFulfilled = (state: IAppState, action: any) => {
    const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventReadings, [
        action.meta?.arg?.insightId,
        action.meta?.arg?.eventId,
    ]);

    if (callKey) {
        delete state.activeHttpCalls[callKey];
    }

    state.errors[callKey] = UiHelper.CreateErrorMessage({
        level: ConstantsHelper.ErrorLevels.success,
        message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.eventReadings'),
    });

    state.loadingSemaphore--;
};

export default fetchInsightEventReadingsFulfilled;
