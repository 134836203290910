import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { sendAzureEvent } from 'src/helpers/appInsights';

import { IInsightFeedback, IParams } from '../types';
import { setValuesFromStateInLocalStorage } from '../helpers/SessionHelper';
import { RootState } from '../store/store';
import { setAnchorEvent } from '../store/ui/ui.slice';
import { setDashboardMetaData } from '../store/dashboard/dashboard.slice';
import { setContributingEventMetaData, setInsightId } from '../store/insights/insights.slice';
import { sendFeedback } from '../store/insights/insights.thunks';
import InsightEdNotes from '../components/insight/InsightEdNotes';
import InsightUserFeedback from '../components/insight/InsightUserFeedback';
import InsightEdWarning from '../components/insight/InsightEdWarning';
import InsightHelper from '../helpers/InsightHelper';
import SystemHelper from '../helpers/SystemHelper';
import InsightDetail from '../components/insight/InsightDetail';
import styleGeneral from '../styles/general.module.scss';
import BtnBack from '../components/common/btnBack';
import ConstantsHelper from '../helpers/ConstantsHelper';
import NavigationHelper from '../helpers/NavigationHelper';
import UiHelper from '../helpers/UiHelper';

function InsightDetails() {
    const dispatch = useDispatch();

    const anI18Nextlib = useSelector((state: RootState) => state.app.anI18Nextlib);
    const activeHttpCalls = useSelector((state: RootState) => state.app.activeHttpCalls);
    const profileData = useSelector((state: RootState) => state.profile.data);
    const anchorEvent = useSelector((state: RootState) => state.ui.anchorEvent);
    const dashboardHasReferrer = useSelector((state: RootState) => state.dashboard.dashboardHasReferrer);
    const dashboardBeg = useSelector((state: RootState) => state.dashboard.dashboardBeg);
    const dashboardEnd = useSelector((state: RootState) => state.dashboard.dashboardEnd);
    const insightId = useSelector((state: RootState) => state.insights.insightId);
    const insightIds = useSelector((state: RootState) => state.insights.insightIds);
    const currentReadingCombo = useSelector((state: RootState) => state.insights.currentReadingCombo);
    const contributingEventBeg = useSelector((state: RootState) => state.insights.contributingEventBeg);
    const contributingEventEnd = useSelector((state: RootState) => state.insights.contributingEventEnd);
    const cachedInsights = useSelector((state: RootState) => state.insights.cachedInsights);

    const bodyRef = useRef<HTMLDivElement>();

    const translate = (key: string, subs?: any) => UiHelper.Translate(anI18Nextlib, key, subs);

    const {
        dashboardBeg: dashboardBegParam,
        dashboardEnd: dashboardEndParam,
        dashboardHasReferrer: dashboardHasReferrerParam,
        insightId: insightIdParam,
    } = useParams<IParams>();

    const handleScroll = (event: any) =>
        NavigationHelper.HandleScroll(
            undefined,
            scrollActive1,
            scrollActive2,
            setScrollActive1,
            setScrollActive2,
            event?.target?.scrollTop
        );

    const armEventReadings = ({
        anchorEvent,
        contributingEventId: contributingEventIdParam,
        contributingEventBeg: contributingEventBegParam,
        contributingEventEnd: contributingEventEndParam,
    }: any) => {
        dispatch(
            setContributingEventMetaData({
                contributingEventId: contributingEventIdParam,
                contributingEventBeg: contributingEventBegParam,
                contributingEventEnd: contributingEventEndParam,
            })
        );

        setValuesFromStateInLocalStorage({
            [ConstantsHelper.IntersessionKeys.contributingEventId]: currentReadingCombo,
            [ConstantsHelper.IntersessionKeys.contributingEventBeg]: contributingEventBeg,
            [ConstantsHelper.IntersessionKeys.contributingEventEnd]: contributingEventEnd,
        });

        dispatch(setAnchorEvent({ anchorEvent }));
    };

    const setFeedback = (payload: IInsightFeedback) => dispatch(sendFeedback(payload));

    useEffect(() => {
        /* istanbul ignore next: no else condition */
        if (
            !(
                dashboardBeg === dashboardBegParam &&
                dashboardEnd === dashboardEndParam &&
                dashboardHasReferrer === (dashboardHasReferrerParam === 'true')
            )
        ) {
            dispatch(
                setDashboardMetaData({
                    dashboardBeg: dashboardBegParam,
                    dashboardEnd: dashboardEndParam,
                    dashboardHasReferrer: dashboardHasReferrerParam === 'true',
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardBegParam, dashboardEndParam, dashboardHasReferrerParam]);

    useEffect(() => {
        if (insightId !== insightIdParam) {
            dispatch(setInsightId({ insightId: insightIdParam }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insightIdParam]);

    const insight = cachedInsights[insightId];

    const clearAnchorEvent = () => dispatch(setAnchorEvent({ anchorEvent: undefined }));

    const [scrollActive1, setScrollActive1] = useState(false);
    const [scrollActive2, setScrollActive2] = useState(false);

    useLayoutEffect(() => {
        if (insight?.insightId && insight?.insightType && insight?.patternType) {
            SystemHelper.AppInsightsEvent(anI18Nextlib, 'InsightDetails()', 'Mounted');
            sendAzureEvent(ConstantsHelper.analyticsEventNames.VIEW_INSIGHT_DETAILS, {
                insightId: insight?.insightId,
                insightType: insight?.insightType,
                patternType: insight?.patternType,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (profileData?.receivedData && !(dashboardEnd && insightId && insightIds)) {
            const payload = UiHelper.GetInsightsDateRange(
                dispatch,
                insightIds,
                dashboardBeg,
                dashboardEnd,
                dashboardHasReferrer
            );

            if (payload.beg && payload.end) {
                UiHelper.ClearErrors(dispatch);
                UiHelper.FetchDashboard(dispatch, payload.beg, payload.end, activeHttpCalls, profileData);
                UiHelper.FetchDashboardInsulinUsage(dispatch, payload.beg, payload.end, activeHttpCalls, profileData);
                UiHelper.FetchDashboardInsulinUsageTrend(
                    dispatch,
                    payload.beg,
                    payload.end,
                    activeHttpCalls,
                    profileData
                );
                UiHelper.FetchDashboardSummary(dispatch, payload.beg, payload.end, activeHttpCalls, profileData);
                UiHelper.FetchDashboardSummaryGlucoseTrend(
                    dispatch,
                    payload.beg,
                    payload.end,
                    activeHttpCalls,
                    profileData
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dashboardEnd,
        profileData?.receivedData,
        profileData?.confidentialityAgreementAccepted,
        profileData?.eulaAccepted,
    ]);

    useEffect(() => {
        if (anchorEvent && insight) {
            NavigationHelper.ScrollIntoView(anchorEvent, clearAnchorEvent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anchorEvent, insight]);

    const insightAttributes = InsightHelper.GetInsightAttributes(anI18Nextlib, cachedInsights, insight, translate);

    if (!insightIds) {
        return null;
    }

    return (
        <div className={styleGeneral.body} onScroll={(event) => handleScroll(event)} ref={bodyRef}>
            <div className={styleGeneral.stickToTopBack}>
                <BtnBack url={NavigationHelper.GenUrlDashboard(dashboardBeg, dashboardEnd, dashboardHasReferrer)} />
            </div>

            <div className={styleGeneral.insightEntryDetail}>
                <InsightDetail
                    cbClickEventFn={armEventReadings}
                    translate={translate}
                    insight={insight}
                    insightAttributes={insightAttributes}
                    parentScrollNode={bodyRef.current}
                    scrollActive1={scrollActive1}
                />
            </div>

            <div className={styleGeneral.insightEntryFooter}>
                <InsightEdNotes notes={insightAttributes.edNotes} translate={translate} />

                <InsightUserFeedback
                    cbClickFeedbackFn={(params: IInsightFeedback) => setFeedback(params)}
                    insight={insight}
                    translate={translate}
                />

                <InsightEdWarning notes={insightAttributes.edNotes} translate={translate} />
            </div>
        </div>
    );
}

export default InsightDetails;
