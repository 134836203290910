import { createSlice } from '@reduxjs/toolkit';

import { fetchClinics } from './clinics.thunks';
import { clinicsInitialState } from './clinics.initialState';
import fetchClinicsFulfilled from './extraReducers/fetchClinics/fulfilled.extraReducer';
import clinicsReducers from './clinics.reducers';

import { resetStore } from '../root.actions';

const clinicsSlice = createSlice({
    name: 'clinics',
    initialState: clinicsInitialState,
    reducers: clinicsReducers,
    extraReducers: (builder) => {
        builder.addCase(resetStore, () => clinicsInitialState).addCase(fetchClinics.fulfilled, fetchClinicsFulfilled);
    },
});

export default clinicsSlice.reducer;
