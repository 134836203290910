import { IDashboardState } from '../../dashboard.types';

const restoreStateFromLocalStorageFulfilled = (state: IDashboardState) => {
    if (state.dashboardHasReferrer) {
        state.dashboardHasReferrer = false;
        state.dashboardBeg = undefined;
        state.dashboardEnd = undefined;
    }
};

export default restoreStateFromLocalStorageFulfilled;
