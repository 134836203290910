import { createSlice } from '@reduxjs/toolkit';

import { IUIState } from './ui.types';
import restoreStateFromLocalStorageFulfilled from './extraReducers/restoreStateFromLocalStorage/fulfilled.extraReducer';
import uiReducers from './ui.reducers';

import { resetStore, restoreStateFromLocalStorage } from '../root.actions';

export const uiInitialState: IUIState = {
    carouselIndex: 0,
    isMenuOpen: false,
    isMenuVisible: true,
    anchorInsight: undefined,
    anchorEvent: undefined,
    anchorAllReports: undefined,
    anchorLoyaltyCongrats: undefined,
    anchorLoyaltyInsulin: undefined,
    showLegend: true,
    windowSize: {
        height: 0,
        width: 0,
    },
};

const uiSlice = createSlice({
    name: 'ui',
    initialState: uiInitialState,
    reducers: uiReducers,
    extraReducers: (builder) => {
        builder
            .addCase(resetStore, () => uiInitialState)
            .addCase(restoreStateFromLocalStorage, restoreStateFromLocalStorageFulfilled);
    },
});

export const {
    setAnchorAllReports,
    setAnchorEvent,
    setAnchorInsight,
    setAnchorLoyaltyCongrats,
    setAnchorLoyaltyInsulin,
    setIsMenuOpen,
    setIsMenuVisible,
    setCarouselIndex,
    setShowLegend,
    setWindowSize,
} = uiSlice.actions;

export default uiSlice.reducer;
