import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import LoyaltyInsulinUsageBlock from './loyaltyInsulinUsageBlock';
import LoyaltyInsulinModeUsageChart from './LoyaltyInsulinModeUsageChart';

import { ITranslator } from '../../../types';
import { IInsulinUsage } from '../../../store/dashboard/dashboard.types';
import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import BtnPrimary from '../../common/btnPrimary';
import NavigationHelper from '../../../helpers/NavigationHelper';
import UiHelper from '../../../helpers/UiHelper';
import { RootState } from '../../../store/store';

const anchor = 'loyaltyInsulinUsageAnchor';

const insulinUsage = ({
    dashboardBeg,
    dashboardEnd,
    dashboardHasReferrer,
    currentInsulinUsage,
    translate,
    cbLoyaltyInsulin,
}: {
    dashboardBeg: string;
    dashboardEnd: string;
    dashboardHasReferrer: boolean;
    currentInsulinUsage: IInsulinUsage;
    translate: ITranslator;
    cbLoyaltyInsulin: any;
}) => (
    <div data-testid="loyalty_insulin_mode_usage" id={anchor} key={anchor} className={styleGuide.InsightCard}>
        <div className={styleGuide.whiteBackgroundAlt}>
            <div className={styleGuide.headerLoyaltyAlt}>
                <div className={styleGuide.loyaltyInsulinUsage}>
                    <LoyaltyInsulinUsageBlock translate={translate} tokenHeader={'loyalty.insulin.title'} />
                    <LoyaltyInsulinModeUsageChart showUnits={false} />

                    <div className={styleGuide.loyaltyInsulinUsageRow}>
                        <div className={clsx(styleGeneral.table, styleGuide.loyaltyRow3)}>
                            <div className={clsx(styleGeneral.row, styleGuide.top)}>
                                <span className={clsx(styleGeneral.cell, styleGuide.cell)}>
                                    <span className={clsx(styleGuide.caption)}>
                                        {translate('loyalty.insulin.avgTotalDailyInsulin')}
                                    </span>
                                </span>
                                <span className={clsx(styleGeneral.cell, styleGuide.cell)}>
                                    <span className={clsx(styleGuide.caption)}>
                                        {translate('loyalty.insulin.avgNumOfBolusesPerDay')}
                                    </span>
                                </span>
                            </div>
                            <div className={clsx(styleGeneral.row, styleGuide.bottom)}>
                                <span className={clsx(styleGeneral.cell, styleGuide.cell)}>
                                    {currentInsulinUsage.dailyUsage.avgInsulinTotal}
                                    <span className={styleGuide.loyaltyValueUnit}>U</span>
                                </span>
                                <span className={clsx(styleGeneral.cell, styleGuide.cell)}>
                                    {currentInsulinUsage.dailyUsage.bolusStatistics?.avgNumber}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={styleGuide.loyaltyInsulinUsageRow}>
                        <BtnPrimary
                            data-testid={`anchor_${anchor}`}
                            caption={translate('viewDetails')}
                            cbClickFn={() => cbLoyaltyInsulin(anchor)}
                            url={NavigationHelper.GenUrlInsulinUsageInsights(
                                dashboardBeg,
                                dashboardEnd,
                                dashboardHasReferrer
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

function LoyaltyInsulinModeUsage({ cbLoyaltyInsulin }: { cbLoyaltyInsulin?: any }) {
    const anI18Nextlib = useSelector((state: RootState) => state.app.anI18Nextlib);

    const translate = (key: string, subs?: any) => UiHelper.Translate(anI18Nextlib, key, subs);

    const currentInsulinUsage = useSelector((state: RootState) => state.dashboard.currentInsulinUsage);
    const dashboardBeg = useSelector((state: RootState) => state.dashboard.dashboardBeg);
    const dashboardEnd = useSelector((state: RootState) => state.dashboard.dashboardEnd);
    const dashboardHasReferrer = useSelector((state: RootState) => state.dashboard.dashboardHasReferrer);

    return !currentInsulinUsage?.dailyUsage?.insulinRatio
        ? null
        : insulinUsage({
              dashboardBeg,
              dashboardEnd,
              dashboardHasReferrer,
              currentInsulinUsage,
              translate,
              cbLoyaltyInsulin: cbLoyaltyInsulin,
          });
}

export default LoyaltyInsulinModeUsage;
