import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';

import { IIDataItemReadings } from './insights.types';

import { IDashboardRequest, IInsightFeedback, IReadingSub } from '../../types';
import { RootState } from '../store';
import { ICachedResponse, ThunkError } from '../root.types';
import AnI18NextLibHelper from '../../helpers/AnI18NextLibHelper';
import InsightHelper from '../../helpers/InsightHelper';
import SystemHelper from '../../helpers/SystemHelper';

export const sendFeedback = createAsyncThunk<IInsightFeedback, IInsightFeedback, { rejectValue: ThunkError }>(
    'patient​/sendFeedback',
    async (feedback: IInsightFeedback, { getState, rejectWithValue }: any) => {
        const state = getState() as RootState;
        const appState = state.app;
        const authenticationState = state.authentication;

        const url = `${appState.endpointPwdUrlPrefix}/patient/insights/feedback`;
        const response = await SystemHelper.Fetch(
            appState,
            authenticationState,
            url,
            {
                method: 'POST',
                body: JSON.stringify({ feedback }),
            },
            true
        );

        if (!(response.status === StatusCodes.OK || response.status === StatusCodes.NO_CONTENT)) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedFeedback')} (${
                    response.msg
                })`,
            });
        }

        return feedback;
    }
);

export const fetchInsightEventReadings = createAsyncThunk<
    ICachedResponse<IIDataItemReadings>,
    IDashboardRequest,
    { rejectValue: ThunkError }
>('patient​/fetchInsightEventReadings', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;
    const insightsState = state.insights;

    let dataReadings;
    const insightId = InsightHelper.ConvertInsightIdSingleToChildAndParent(params?.insightId).child;
    const url = `${appState.endpointPwdUrlPrefix}/patient/insights/${insightId}/${params.eventId}/readings?`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, undefined, true);

    if (response.status !== StatusCodes.OK) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedEventReadings')} (${
                response.msg
            })`,
        });
    } else {
        const readings = response.data?.data?.item?.readings ?? [];

        dataReadings = {
            readings: readings,
        };
    }

    return {
        id: params.eventId,
        id2: insightId,
        data: {
            ...insightsState.currentReadingCombo,
            ...dataReadings,
            fetchedReadings: true,
        },
    };
});

export const fetchInsightEventBoluses = createAsyncThunk<
    ICachedResponse<IIDataItemReadings>,
    IDashboardRequest,
    { rejectValue: ThunkError }
>('patient​/fetchInsightEventBoluses', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;
    const insightsState = state.insights;

    let dataReadings;
    const insightId = InsightHelper.ConvertInsightIdSingleToChildAndParent(params?.insightId).child;
    const url = `${appState.endpointPwdUrlPrefix}/patient/insights/${insightId}/${params.eventId}/boluses?`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, undefined, true);

    if (
        !(
            response.status === StatusCodes.OK ||
            response.status === StatusCodes.NOT_FOUND ||
            response.status === StatusCodes.INTERNAL_SERVER_ERROR
        )
    ) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedEventBoluses')} (${
                response.msg
            })`,
        });
    } else {
        dataReadings = {
            boluses: response.data?.data?.item?.boluses ?? [],
        };
    }

    return {
        id: params.eventId,
        id2: insightId,
        data: {
            ...insightsState.currentReadingCombo,
            ...dataReadings,
            fetchedBoluses: true,
        },
    };
});

export const fetchInsightEventModes = createAsyncThunk<
    ICachedResponse<IReadingSub>,
    IDashboardRequest,
    { rejectValue: ThunkError }
>('patient​/fetchInsightEventModes', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;
    const insightsState = state.insights;

    let dataReadings;
    const insightId = InsightHelper.ConvertInsightIdSingleToChildAndParent(params?.insightId).child;
    const url = `${appState.endpointPwdUrlPrefix}/patient/insights/${insightId}/${params.eventId}/modes?`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, undefined, true);

    if (
        !(
            response.status === StatusCodes.OK ||
            response.status === StatusCodes.NOT_FOUND ||
            response.status === StatusCodes.INTERNAL_SERVER_ERROR
        )
    ) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedEventModes')} (${
                response.msg
            })`,
        });
    } else {
        dataReadings = {
            modes: response.data?.data?.item?.modes ?? [],
            podStatuses: response.data?.data?.item?.podStatuses ?? [],
            submodes: response.data?.data?.item?.submodes ?? [],
        };
    }

    return {
        id: params.eventId,
        id2: insightId,
        data: {
            ...insightsState.currentReadingCombo,
            ...dataReadings,
            fetchedModes: true,
        },
    };
});
