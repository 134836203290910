import { createSlice } from '@reduxjs/toolkit';

import {
    fetchDashboardInsulinUsage,
    fetchDashboardInsulinUsageTrend,
    fetchDashboardSummary,
    fetchDashboardSummaryGlucoseTrend,
} from './dashboard.thunks';
import { dashboardInitialState } from './dashboard.initialState';
import dashboardReducers from './dashboard.reducers';
import restoreStateFromLocalStorageFulfilled from './extraReducers/restoreStateFromLocalStorage/fulfilled.extraReducer';
import fetchDashboardInsulinUsageFulfilled from './extraReducers/fetchDashboardInsulinUsage/fulfilled.extraReducer';
import fetchDashboardInsulinUsageTrendFulfilled from './extraReducers/fetchDashboardInsulinUsageTrend/fulfilled.extraReducer';
import fetchDashboardSummaryFulfilled from './extraReducers/fetchDashboardSummary/fulfilled.extraReducer';
import fetchDashboardSummaryGlucoseTrendFulfilled from './extraReducers/fetchDashboardSummaryGlucoseTrend/fulfilled.extraReducer';

import { resetStore, restoreStateFromLocalStorage } from '../root.actions';

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: dashboardInitialState,
    reducers: dashboardReducers,
    extraReducers: (builder) => {
        builder
            .addCase(resetStore, () => dashboardInitialState)
            .addCase(restoreStateFromLocalStorage, restoreStateFromLocalStorageFulfilled)
            .addCase(fetchDashboardInsulinUsage.fulfilled, fetchDashboardInsulinUsageFulfilled)
            .addCase(fetchDashboardInsulinUsageTrend.fulfilled, fetchDashboardInsulinUsageTrendFulfilled)
            .addCase(fetchDashboardSummary.fulfilled, fetchDashboardSummaryFulfilled)
            .addCase(fetchDashboardSummaryGlucoseTrend.fulfilled, fetchDashboardSummaryGlucoseTrendFulfilled);
    },
});

export const {
    actions: { setDashboardMetaData },
} = dashboardSlice;

export default dashboardSlice.reducer;
