import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';

import {
    DataResponseInsight3,
    DataResponseInsight3GlucoseTrend,
    DataResponseInsulinUsageItem,
    DataResponseInsulinUsageTrendItem,
} from '../../model/models';
import {
    IDashboardRequest,
    IDashboardFetchPayload,
    IDashboardSummaryFetchPayload,
    IDashboardSummaryGlucoseTrendFetchPayload,
} from '../../types';
import { RootState } from '../store';
import { ICachedResponse, ThunkError } from '../root.types';
import { setInsightsData } from '../insights/insights.slice';
import AnI18NextLibHelper from '../../helpers/AnI18NextLibHelper';
import SystemHelper from '../../helpers/SystemHelper';

export const fetchDashboardInsulinUsage = createAsyncThunk<
    ICachedResponse<DataResponseInsulinUsageItem>,
    IDashboardRequest,
    { rejectValue: ThunkError }
>('patient​/fetchDashboardInsulinUsage', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;

    const dashboardInsulinUsage: DataResponseInsulinUsageItem = {
        item: null,
    };
    const queryParams = [`beg=${params.beg}`, `end=${params.end}`];
    const url = `${appState.endpointPwdUrlPrefix}/patient/dashboard/insulin-usage?${queryParams.join('&')}`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, false, true);
    const haveValidStatus =
        response.status === StatusCodes.OK ||
        response.status === StatusCodes.NOT_FOUND ||
        response.status === StatusCodes.BAD_REQUEST ||
        response.status === StatusCodes.INTERNAL_SERVER_ERROR;

    if (response.status === StatusCodes.OK) {
        dashboardInsulinUsage.item = response.data?.data?.item;
    } else {
        dashboardInsulinUsage.item = {
            dailyUsage: {},
            displayUsageExplanation: {},
        };
    }

    if (!haveValidStatus) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedDashboardInsulinUsage')} (${
                response.msg
            })`,
        });
    }

    return {
        data: dashboardInsulinUsage,
    };
});

export const fetchDashboardInsulinUsageTrend = createAsyncThunk<
    ICachedResponse<DataResponseInsulinUsageTrendItem>,
    IDashboardRequest,
    { rejectValue: ThunkError }
>('patient​/fetchDashboardInsulinUsageTrend', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;

    const dashboardInsulinUsageTrend: DataResponseInsulinUsageTrendItem = {
        item: null,
    };
    const queryParams = [`beg=${params.beg}`, `end=${params.end}`];
    const url = `${appState.endpointPwdUrlPrefix}/patient/dashboard/insulin-usage/trend?${queryParams.join('&')}`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, false, true);
    const haveValidStatus =
        response.status === StatusCodes.OK ||
        response.status === StatusCodes.NOT_FOUND ||
        response.status === StatusCodes.BAD_REQUEST ||
        response.status === StatusCodes.INTERNAL_SERVER_ERROR;

    if (response.status === StatusCodes.OK) {
        dashboardInsulinUsageTrend.item = response.data?.data?.item;
    } else {
        dashboardInsulinUsageTrend.item = {
            weeklyTrend: {},
        };
    }

    if (!haveValidStatus) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(
                appState?.anI18Nextlib,
                'errors.failedDashboardInsulinUsageTrend'
            )} (${response.msg})`,
        });
    }

    return {
        data: dashboardInsulinUsageTrend,
    };
});

export const fetchDashboardSummary = createAsyncThunk<
    ICachedResponse<IDashboardSummaryFetchPayload>,
    IDashboardRequest,
    { rejectValue: ThunkError }
>('patient​/fetchDashboardSummary', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;

    const dashboardSummaryFetchPayload: IDashboardSummaryFetchPayload = {
        item: null,
    };
    const queryParams = [`beg=${params.beg}`, `end=${params.end}`];
    const url = `${appState.endpointPwdUrlPrefix}/patient/dashboard/summary?${queryParams.join('&')}`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, false, true);

    if (response.status === StatusCodes.OK) {
        const wrapper: DataResponseInsight3 = response.data;

        dashboardSummaryFetchPayload.item = wrapper?.data?.item;
    } else if (response.status === StatusCodes.UNAUTHORIZED || response.status === StatusCodes.NOT_FOUND) {
        dashboardSummaryFetchPayload.item = [] as any;
    }

    if (dashboardSummaryFetchPayload.item === null) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedDashboardSummary')} (${
                response.msg
            })`,
        });
    }

    return {
        data: dashboardSummaryFetchPayload,
    };
});

export const fetchDashboardSummaryGlucoseTrend = createAsyncThunk<
    ICachedResponse<IDashboardSummaryGlucoseTrendFetchPayload>,
    IDashboardRequest,
    { rejectValue: ThunkError }
>(
    'patient​/fetchDashboardSummaryGlucoseTrend',
    async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
        const state = getState() as RootState;
        const appState = state.app;
        const authenticationState = state.authentication;

        const dashboardSummaryGlucoseTrendFetchPayload: IDashboardSummaryGlucoseTrendFetchPayload = {
            item: null,
        };

        const queryParams = [`beg=${params.beg}`, `end=${params.end}`];
        const url = `${appState.endpointPwdUrlPrefix}/patient/dashboard/summary/glucose-trend?${queryParams.join('&')}`;
        const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, false, true);

        if (response.status === StatusCodes.OK) {
            const wrapper: DataResponseInsight3GlucoseTrend = response.data;

            dashboardSummaryGlucoseTrendFetchPayload.item = wrapper?.data?.item;
        } else if (response.status === StatusCodes.UNAUTHORIZED || response.status === StatusCodes.NOT_FOUND) {
            dashboardSummaryGlucoseTrendFetchPayload.item = [] as any;
        }

        if (dashboardSummaryGlucoseTrendFetchPayload.item === null) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(
                    appState?.anI18Nextlib,
                    'errors.failedDashboardSummaryGlucoseTrend'
                )} (${response.msg})`,
            });
        }

        return {
            id: params.endUtc,
            data: dashboardSummaryGlucoseTrendFetchPayload,
        };
    }
);

export const fetchDashboard = createAsyncThunk<
    ICachedResponse<IDashboardFetchPayload>,
    IDashboardRequest,
    { rejectValue: ThunkError }
>('patient​/fetchDashboard', async (params: IDashboardRequest, { dispatch, getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;

    const dashboardFetchPayload: IDashboardFetchPayload = {
        items: null,
        totalCount: 0,
    };
    const queryParams = [`beg=${params.beg}`, `end=${params.end}`, `limit=${params.limit}`, `offset=${params.offset}`];
    const url = `${appState.endpointPwdUrlPrefix}/patient/dashboard?${queryParams.join('&')}`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, false, true);

    if (response.status === StatusCodes.OK) {
        const wrapper: IDashboardFetchPayload = response.data?.data;

        dashboardFetchPayload.items = wrapper.items;
        dashboardFetchPayload.totalCount = wrapper.totalCount;
    } else if (response.status === StatusCodes.UNAUTHORIZED || response.status === StatusCodes.NOT_FOUND) {
        dashboardFetchPayload.items = [];
    }

    if (dashboardFetchPayload.items === null) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedDashboard')} (${
                response.msg
            })`,
        });
    }

    dispatch(setInsightsData(dashboardFetchPayload));
});
