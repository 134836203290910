import { IInsightsState } from '../../insights.types';

const sendFeedbackFulfilled = (state: IInsightsState, action: any) => {
    if (action.payload) {
        [action.payload.insightId, action.payload.insightIdParent].forEach((insightId) => {
            if (insightId) {
                const insight = state.cachedInsights[insightId];

                if (insight) {
                    insight.feedbackValue = action.payload.feedbackValue;
                }
            }
        });
    }
};

export default sendFeedbackFulfilled;
