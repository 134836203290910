import clsx from 'clsx';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'src/store/store';

import { ICallback } from '../../../types';
import BtnPrimary from '../btnPrimary';
import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import ConstantsHelper from '../../../helpers/ConstantsHelper';
import logoWarning from '../../../assets/images/warning-icon.png';
import CheckSingle from '../checkSingle';
import NavigationHelper from '../../../helpers/NavigationHelper';
import UiHelper from '../../../helpers/UiHelper';
import SystemHelper from '../../../helpers/SystemHelper';

const idBtnContinue = 'idBtnContinue';

function Disclaimer({
    closeCb,
}: Readonly<{
    closeCb: ICallback<void>;
}>) {
    const anI18Nextlib = useSelector((state: RootState) => state.app.anI18Nextlib);

    const translate = (key: string, subs?: any) => UiHelper.Translate(anI18Nextlib, key, subs);

    const browserInfo = SystemHelper.GetBrowserInfo();
    const isProduction = SystemHelper.IsProd();

    const [check1, setCheck1] = useState(false);
    const [check2, setCheck2] = useState(false);
    const setCheck = (setterFn: any, valueToSet: boolean) => {
        setterFn(valueToSet);
        NavigationHelper.ScrollIntoView(idBtnContinue);
    };
    const cbAccept = () => {
        closeCb();

        if (window.scrollY > 0) {
            window.scrollTo(0, 0);
        }
    };
    const handleDiscalimerCaptionCb = (translateKey1: string, translateKey2: string) => (
        <span className={styleGuide.checkBoxLabel}>
            {translate(translateKey1, {
                nameAlt: translate(translateKey2),
            })}
        </span>
    );

    return (
        <div
            className={clsx(
                styleGeneral.layerTopmostLess1,
                UiHelper.GetBannerSpacingClasses(
                    isProduction,
                    browserInfo.supported,
                    styleGuide.modalBackdrop,
                    styleGuide.modalBackdropOneBanner,
                    styleGuide.modalBackdropTwoBanners
                ),
                styleGuide.modalBackdropTranslucent
            )}
        >
            <div className={clsx(styleGeneral.layerTopmostLess1, styleGuide.disclaimer)}>
                <img src={logoWarning} alt={translate('disclaimer.altWarning')} className={styleGuide.icon} />
                <div className={styleGuide.title}>{translate('disclaimer.warning')}</div>
                <div className={styleGuide.par}>
                    {translate('disclaimer.par1', {
                        name: translate(ConstantsHelper.NameTag),
                        nameAlt: translate(ConstantsHelper.NameTagAlt2),
                        hyperHypo: translate(ConstantsHelper.CaptionHyperHypo),
                    })}
                    <div className={styleGuide.checkBoxContainer}>
                        <CheckSingle
                            data-testid={'disclaimerChk1'}
                            id="disclaimerChk1"
                            checked={check1}
                            cbClickFn={(e: any) => setCheck(setCheck1, e?.checked)}
                            captionCb={() => handleDiscalimerCaptionCb('disclaimer.chk1', ConstantsHelper.NameTagAlt2)}
                        />
                    </div>
                    <div className={styleGuide.checkBoxContainer}>
                        <CheckSingle
                            data-testid={'disclaimerChk2'}
                            id="disclaimerChk2"
                            checked={check2}
                            cbClickFn={(e: any) => setCheck(setCheck2, e?.checked)}
                            captionCb={() => handleDiscalimerCaptionCb('disclaimer.chk2', ConstantsHelper.NameTag)}
                        />
                    </div>
                </div>
                <div className={styleGuide.parLast} id={idBtnContinue}>
                    <BtnPrimary
                        disabled={!(check1 && check2)}
                        caption={translate('disclaimer.continue')}
                        cbClickFn={() => cbAccept()}
                        focus={false}
                    />
                </div>
            </div>
        </div>
    );
}

export default Disclaimer;
