import { IAppState } from '../../app.types';
import ConstantsHelper from '../../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../../helpers/UtilityHelper';
import UiHelper from '../../../../helpers/UiHelper';
import AnI18NextLibHelper from '../../../../helpers/AnI18NextLibHelper';

const fetchInsightEventBolusesPending = (state: IAppState, action: any) => {
    state.loadingSemaphore++;

    const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventBoluses, [
        action.meta?.arg?.insightId,
        action.meta?.arg?.eventId,
    ]);

    state.activeHttpCalls[callKey] = true;

    state.errors[callKey] = UiHelper.CreateErrorMessage({
        level: ConstantsHelper.ErrorLevels.info,
        message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.eventBoluses'),
    });
};

export default fetchInsightEventBolusesPending;
