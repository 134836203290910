import { useOktaAuth } from '@okta/okta-react';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { signInUrlCreator } from 'src/store/app/app.slice';
import { sendAzureEvent } from 'src/helpers/appInsights';
import UiHelper from 'src/helpers/UiHelper';

import LoginError from './LoginError';

import { RootState } from '../store/store';
import ConstantsHelper from '../helpers/ConstantsHelper';
import styleGuide from '../styles/styleGuide.module.scss';

export const sendLoginAnalyticsEvent = (analyticsEventName: string) =>
    sendAzureEvent(analyticsEventName, { signInUrl: document.URL });

function LoginCallback() {
    const dispatch = useDispatch();

    const { oktaAuth, authState } = useOktaAuth();

    const [callbackError, setCallbackError] = useState(null);

    const anI18Nextlib = useSelector((state: RootState) => state.app.anI18Nextlib);

    const translate = (key: string, subs?: any) => UiHelper.Translate(anI18Nextlib, key, subs);

    useEffect(() => {
        oktaAuth
            .handleLoginRedirect()
            .then(() => {
                sendLoginAnalyticsEvent(ConstantsHelper.analyticsEventNames.SUCCESSFUL_LOGIN);
                dispatch(signInUrlCreator({ signInUrl: document.URL }));
            })
            .catch((e) => {
                setCallbackError(e);
                sendLoginAnalyticsEvent(ConstantsHelper.analyticsEventNames.FAILED_LOGIN);
                dispatch(signInUrlCreator({ signInUrl: document.URL }));
            });
    }, [oktaAuth, dispatch]);

    useEffect(() => {
        if (authState?.error) {
            sendLoginAnalyticsEvent(ConstantsHelper.analyticsEventNames.FAILED_LOGIN);
            dispatch(signInUrlCreator({ signInUrl: document.URL }));
        }
    }, [authState?.error, dispatch]);

    const authError = authState?.error;
    const displayError = callbackError || authError;

    if (displayError) {
        return (
            <div className={styleGuide.loginCallback}>
                <LoginError error={displayError} translate={translate} />
            </div>
        );
    }

    return null;
}

export default LoginCallback;
