import { IDashboardState, IInsulinUsage } from '../../dashboard.types';

const fetchDashboardInsulinUsageFulfilled = (state: IDashboardState, action: any) => {
    if (action.payload) {
        const currentInsulinUsage: IInsulinUsage = {
            ...state.currentInsulinUsage,
            ...action.payload.data.item,
        };
        state.currentInsulinUsage = currentInsulinUsage;
    }
};

export default fetchDashboardInsulinUsageFulfilled;
