import { OktaAuth } from '@okta/okta-auth-js';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import UiHelper from 'src/helpers/UiHelper';

interface IOktaMockFormProps {
    oktaAuth: OktaAuth;
}

function OktaMockForm({ oktaAuth }: IOktaMockFormProps) {
    const { t } = useTranslation();

    const [claims, setClaims] = useState<string>('');

    const getAndSetMockToken = async () => {
        if (claims) {
            const claimsJson = JSON.parse(claims);

            const token = await UiHelper.getOktaMockToken(claimsJson);

            console.log(token);

            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 22);

            const expirationDateTimestamp = Math.floor(expirationDate.getTime() / 1000);

            oktaAuth.tokenManager.setTokens({
                idToken: {
                    idToken: token,
                    claims: claimsJson,
                    expiresAt: expirationDateTimestamp,
                    scopes: ['email', 'profile', 'openid'],
                    authorizeUrl: '',
                    issuer: '',
                    clientId: '',
                },
                accessToken: {
                    accessToken: token,
                    claims: claimsJson,
                    expiresAt: expirationDateTimestamp,
                    tokenType: 'Bearer',
                    scopes: ['email', 'profile', 'openid'],
                    authorizeUrl: '',
                    userinfoUrl: '',
                },
            });
        }

        window.location.assign(`/`);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        getAndSetMockToken();
    };

    return (
        <form onSubmit={handleSubmit} style={{ padding: '15px' }}>
            <h4>{t('oktaMock.claims')}:</h4>
            <div style={{ width: '348px', padding: '1rem 0' }}>
                <textarea style={{ width: '348px', height: '120px' }} onChange={(e) => setClaims(e.target.value)} />
            </div>
            <p>
                <button type="submit"> {t('oktaMock.generateToken')}</button>
            </p>
        </form>
    );
}

export default OktaMockForm;
