import { Clinic } from '../../../../model/clinic';
import { IClinicsState } from '../../clinics.types';

const fetchClinicsFulfilled = (state: IClinicsState, action: any) => {
    const payload: Clinic[] = action.payload;

    state.data = payload;
};

export default fetchClinicsFulfilled;
