import { IAppState } from '../../app.types';
import ConstantsHelper from '../../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../../helpers/UtilityHelper';
import UiHelper from '../../../../helpers/UiHelper';
import AnI18NextLibHelper from '../../../../helpers/AnI18NextLibHelper';

const fetchDashboardInsulinUsageTrendRejected = (state: IAppState, action: any) => {
    const callKey = UtilityHelper.MakeHttpCallKey(
        ConstantsHelper.httpCallAndErrorKeys.fetchDashboardInsulinUsageTrend,
        [action.meta?.arg?.beg, action.meta?.arg?.end]
    );
    if (callKey) {
        delete state.activeHttpCalls[callKey];
    }
    state.errors[callKey] = UiHelper.CreateErrorMessage({
        level: ConstantsHelper.ErrorLevels.error,
        message:
            action?.payload?.message ??
            action?.error?.message ??
            AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardInsulinUsageTrend'),
    });
    state.loadingSemaphore--;
};

export default fetchDashboardInsulinUsageTrendRejected;
