import { Middleware } from '@reduxjs/toolkit';

import { setOktaData } from './authentication/authentication.slice';
import { resetStore } from './root.actions';

const resetMiddleware: Middleware = (store) => (next) => (action) => {
    if (setOktaData.match(action) && action.payload?.isUserDefined === false) {
        next(action);

        store.dispatch(resetStore());
    } else {
        return next(action);
    }
};

export default resetMiddleware;
