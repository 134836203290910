import { IInsightsState } from './insights.types';

export const insightsInitialState: IInsightsState = {
    cachedInsights: {},
    insightId: undefined,
    insightIds: undefined,
    currentReadingCombo: {},
    contributingEventId: undefined,
    contributingEventBeg: undefined,
    contributingEventEnd: undefined,
    readingsInfo: {
        fillGaps: false,
    },
};
