import { IProfileState } from '../../profile.types';
import UtilityHelper from '../../../../helpers/UtilityHelper';

const fetchProfileFulfilled = (state: IProfileState, { payload }: any) => {
    state.data = {
        ...payload,
        firstJoined: UtilityHelper.CryptoEncrypt(payload.firstJoined),
        firstName: UtilityHelper.CryptoEncrypt(payload.firstName),
        id: UtilityHelper.CryptoEncrypt(payload.id),
        lastName: UtilityHelper.CryptoEncrypt(payload.lastName),
        reportDaysAvailableString: UtilityHelper.CryptoEncrypt(`${payload.reportDaysAvailable}`),
        reportDaysAvailable: -999,
    };
};

export default fetchProfileFulfilled;
