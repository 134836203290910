import { IInsightsState } from '../../insights.types';

const fetchInsightEventModesPending = (state: IInsightsState) => {
    state.currentReadingCombo = {
        ...state.currentReadingCombo,
        fetched: true,
    };
};

export default fetchInsightEventModesPending;
