import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { memo, useCallback, useMemo } from 'react';

import Onboarding from './Onboarding';

import { RootState } from '../../store/store';
import SystemHelper from '../../helpers/SystemHelper';
import EnrollmentRevoked from '../common/contentWrappers/EnrollmentRevoked';
import EnrollmentPending from '../common/contentWrappers/EnrollmentPending';

interface IEnrollmentRequiredRoute {
    component: React.FunctionComponent<any>;
    exact: boolean;
    path: string;
}

const EnrollmentRequiredRoute = memo(function EnrollmentRequiredRoute({
    component: Component,
    exact,
    path,
}: Readonly<IEnrollmentRequiredRoute>) {
    const profileData = useSelector((state: RootState) => state.profile.data);

    const showPending = useMemo(() => !profileData.isUnenrolled && !profileData.isEnrolled, [profileData]);

    const podderCentralCb = useCallback(() => {
        window.location.assign(SystemHelper?.GetRuntimeConfig('REACT_APP_PODDER_CENTRAL_URL_LINK'));
    }, []);

    const renderComponent = useCallback(() => {
        if (profileData.isUnenrolled) {
            return <EnrollmentRevoked podderCentralCb={podderCentralCb} />;
        }

        if (showPending) {
            return <EnrollmentPending podderCentralCb={podderCentralCb} />;
        }

        return <Onboarding>{Component && <Component />}</Onboarding>;
    }, [Component, profileData.isUnenrolled, showPending, podderCentralCb]);

    if (!profileData.receivedData) return null;

    return <Route exact={exact} path={path} render={renderComponent} />;
});

export default EnrollmentRequiredRoute;
