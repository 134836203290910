import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector } from 'react-redux';

import ContributingEventChartBloodGlucose from './chart/ContributingEventChartBloodGlucose';
import ContributingEventChartInsulinDelivery from './chart/ContributingEventChartInsulinDelivery';

import { IBolusEntry, IChartPayload, IContributingEvent, IInsightAttr, ITimeRange, ITranslator } from '../../types';
import { InsightInsightsProp } from '../../model/insightInsightsProp';
import { RootState } from '../../store/store';
import { IIDataItemReadings, IInsight } from '../../store/insights/insights.types';
import { setReadingsInfo } from '../../store/insights/insights.slice';
import ConstantsHelper from '../../helpers/ConstantsHelper';
import DateTimeHelper from '../../helpers/DateTimeHelper';
import InsightHelper from '../../helpers/InsightHelper';
import PlankBolus from '../common/plankBolus';
import PlankEvent from '../common/plankEvent';
import styleGeneral from '../../styles/general.module.scss';
import styleGuide from '../../styles/styleGuide.module.scss';
import ChartHelper from '../../helpers/ChartHelper';
import EventHelper from '../../helpers/EventHelper';
import UiHelper from '../../helpers/UiHelper';

function UINonBolus({
    translate,
    anI18Nextlib,
    scrollActive1,
    contributingEvent,
    insightAttributes,
    typeStyle,
}: {
    translate: ITranslator;
    anI18Nextlib: any;
    scrollActive1: boolean;
    contributingEvent: IContributingEvent;
    insightAttributes: IInsightAttr;
    typeStyle: string;
}) {
    return (
        <div
            className={clsx(
                styleGuide.reading,
                styleGuide.banner,
                scrollActive1 && styleGeneral.eventsZoomInScroll1OnScroll2OnBorderOff
            )}
        >
            <div className={styleGuide.row}>
                <span className={styleGuide.label}>{insightAttributes.title2}</span>
                <span className={clsx(styleGuide.value, insightAttributes.style2)}>
                    {`${DateTimeHelper.FormatTimeDelta(anI18Nextlib, contributingEvent.duration, false, true) ?? '--'}`}
                </span>
            </div>
            <div className={styleGuide.row}>
                <span className={styleGuide.label}>{insightAttributes.title}</span>
                <span className={clsx(styleGuide.value, typeStyle)}>
                    {EventHelper.GetEgvLabel(translate, contributingEvent?.extremeBGValue, false)}
                </span>
                <span className={styleGuide.unit}>{ConstantsHelper.UnitsTable.egv}</span>
            </div>
        </div>
    );
}

function UIBolus({
    translate,
    scrollActive1,
    insight,
    contributingEvent,
    bolusDataPre,
    bolusDataPost,
}: {
    translate: ITranslator;
    scrollActive1: boolean;
    insight: IInsight;
    contributingEvent: IContributingEvent;
    bolusDataPre: IBolusEntry[];
    bolusDataPost: IBolusEntry[];
}) {
    return (
        <>
            {bolusDataPre?.map((bolusEntry, idx) => (
                <PlankBolus
                    translate={translate}
                    key={`plankBolus${idx}`}
                    units={ConstantsHelper.UnitsTable}
                    delta={
                        idx > 0
                            ? moment(bolusDataPre[idx].bolusTime).diff(
                                  moment(bolusDataPre[idx - 1].bolusTime),
                                  'seconds'
                              )
                            : -1
                    }
                    bolusEntry={bolusEntry}
                />
            ))}

            <div className={clsx(scrollActive1 && styleGeneral.eventsZoomInScroll1OnScroll2OnBorderOff)}>
                <PlankEvent
                    translate={translate}
                    units={ConstantsHelper.UnitsTable}
                    delta={
                        bolusDataPre?.length > 0
                            ? moment(contributingEvent.beg).diff(
                                  moment(bolusDataPre[bolusDataPre.length - 1].bolusTime),
                                  'seconds'
                              )
                            : -1
                    }
                    event={contributingEvent}
                    patternType={insight.patternType}
                />
            </div>

            {bolusDataPost?.map((bolusEntry, idx) => (
                <PlankBolus
                    translate={translate}
                    key={`plankBolus${idx}`}
                    units={ConstantsHelper.UnitsTable}
                    delta={moment(bolusDataPost[idx].bolusTime).diff(
                        moment(idx === 0 ? contributingEvent.beg : bolusDataPost[idx - 1].bolusTime),
                        'seconds'
                    )}
                    bolusEntry={bolusEntry}
                />
            ))}
        </>
    );
}

const getEventAttributes = (
    translate: ITranslator,
    anI18Nextlib: any,
    insight: IInsight,
    contributingEvent: IContributingEvent,
    bolusDataPre: IBolusEntry[],
    bolusDataPost: IBolusEntry[],
    dataItemReadings: IIDataItemReadings
) => {
    let timeRangeData: ITimeRange;
    let bloodGlucoseDataAttributes: IChartPayload;
    let insulinDeliveryDataAttributes: IChartPayload;

    if (contributingEvent && insight && dataItemReadings) {
        timeRangeData = DateTimeHelper.FormatDateTimeRange(anI18Nextlib, contributingEvent.beg, contributingEvent.end);
        const readingsWithBolusData = dataItemReadings.readingsCombo?.filter((r) => r.boluses?.length > 0);

        if (readingsWithBolusData) {
            readingsWithBolusData.forEach((readingEntry) => {
                const egv = readingEntry.egv;

                readingEntry.boluses?.forEach((bolusEntry) => {
                    const bolusEntryNew: IBolusEntry = {
                        ...bolusEntry,
                        egv,
                    };

                    if (bolusEntry.bolusTime <= contributingEvent.beg) {
                        bolusDataPre.push(bolusEntryNew);
                    } else {
                        bolusDataPost.push(bolusEntryNew);
                    }
                });
            });
        }

        insulinDeliveryDataAttributes = ChartHelper.GetInsulinDeliveryDataAttributes(
            translate('chart.mode'),
            translate('chart.mode2'),
            translate('chart.mode3'),
            dataItemReadings,
            contributingEvent
        );
        bloodGlucoseDataAttributes = ChartHelper.GetBloodGlucoseDataAttributes(
            translate('chart.glucoseTimeline'),
            translate('chart.glucoseTimeline2'),
            dataItemReadings.readingsCombo,
            contributingEvent,
            insight
        );
    }

    return {
        timeRangeData,
        bloodGlucoseDataAttributes,
        insulinDeliveryDataAttributes,
    };
};

function Header({
    scrollActive1,
    insightAttributes,
    timeRangeData,
}: {
    scrollActive1: boolean;
    insightAttributes: IInsightAttr;
    timeRangeData: ITimeRange;
}) {
    return (
        <div
            className={clsx(
                styleGuide.summary,
                styleGeneral.eventsZoomInScroll1OffScroll2Off,
                scrollActive1 && styleGeneral.eventsZoomInScroll1OnScroll2Off
            )}
        >
            <div className={clsx(styleGuide.header, styleGeneral.toCapitalize, scrollActive1 && styleGeneral.inScroll)}>
                {insightAttributes.scrn3Title}
            </div>
            <div className={clsx(styleGuide.timeRange, scrollActive1 && styleGeneral.inScroll)}>
                {timeRangeData.time}
                {timeRangeData.suffix}
            </div>
            <div className={clsx(styleGuide.dateRange, scrollActive1 && styleGeneral.inScroll2)}>
                {timeRangeData.date}
            </div>
        </div>
    );
}

function Body({
    translate,
    anI18Nextlib,
    scrollActive1,
    insight,
    contributingEvent,
    insightAttributes,
    typeStyle,
    bolusDataPre,
    bolusDataPost,
    bloodGlucoseDataAttributes,
    insulinDeliveryDataAttributes,
    marginLeft,
    marginRight,
    xAxisTicks,
}: {
    translate: ITranslator;
    anI18Nextlib: any;
    scrollActive1: boolean;
    insight: IInsight;
    contributingEvent: IContributingEvent;
    insightAttributes: IInsightAttr;
    typeStyle: string;
    bolusDataPre: IBolusEntry[];
    bolusDataPost: IBolusEntry[];
    bloodGlucoseDataAttributes: IChartPayload;
    insulinDeliveryDataAttributes: IChartPayload;
    marginLeft: number;
    marginRight: number;
    xAxisTicks: string[];
}) {
    return (
        <>
            {insight?.insightType === InsightInsightsProp.InsightTypeEnum.Bolus ? (
                <UIBolus
                    translate={translate}
                    scrollActive1={scrollActive1}
                    insight={insight}
                    contributingEvent={contributingEvent}
                    bolusDataPre={bolusDataPre}
                    bolusDataPost={bolusDataPost}
                />
            ) : (
                <UINonBolus
                    translate={translate}
                    anI18Nextlib={anI18Nextlib}
                    scrollActive1={scrollActive1}
                    contributingEvent={contributingEvent}
                    insightAttributes={insightAttributes}
                    typeStyle={typeStyle}
                />
            )}

            <ContributingEventChartBloodGlucose
                xAxisTicks={xAxisTicks}
                bloodGlucoseDataAttributes={bloodGlucoseDataAttributes}
                insight={insight}
                marginLeft={marginLeft}
                marginRight={marginRight}
                translate={translate}
            />

            <ContributingEventChartInsulinDelivery
                insulinDeliveryDataAttributes={insulinDeliveryDataAttributes}
                marginLeft={marginLeft}
                marginRight={marginRight}
            />
        </>
    );
}

function ContributingEventDetail({
    dispatch,
    translate,
    scrollActive1,
    insight,
    contributingEvent,
    dataItemReadings,
}: {
    dispatch: any;
    translate: ITranslator;
    scrollActive1: boolean;
    insight: IInsight;
    contributingEvent: IContributingEvent;
    dataItemReadings: IIDataItemReadings;
}) {
    const anI18Nextlib = useSelector((state: RootState) => state.app.anI18Nextlib);
    const loadingSemaphore = useSelector((state: RootState) => state.app.loadingSemaphore);
    const readingsInfo = useSelector((state: RootState) => state.insights.readingsInfo);
    const windowSize = useSelector((state: RootState) => state.ui.windowSize);
    const cachedInsights = useSelector((state: RootState) => state.insights.cachedInsights);

    const [windowSizeLast, setWindowSizeLast] = useState(windowSize);

    const insightAttributes: IInsightAttr = InsightHelper.GetInsightAttributes(
        anI18Nextlib,
        cachedInsights,
        insight,
        translate
    );
    const typeStyle: string = insightAttributes.style;
    const marginLeft = 24;
    const marginRight = 30;
    const bolusDataPre: IBolusEntry[] = [];
    const bolusDataPost: IBolusEntry[] = [];
    const { timeRangeData, bloodGlucoseDataAttributes, insulinDeliveryDataAttributes } = getEventAttributes(
        translate,
        anI18Nextlib,
        insight,
        contributingEvent,
        bolusDataPre,
        bolusDataPost,
        dataItemReadings
    );
    const xAxisTicks = ChartHelper.GetAxisTicksX(windowSize, insulinDeliveryDataAttributes?.crossOvers);

    useEffect(() => {
        // Force chart components to redraw upon screen resize
        if (!loadingSemaphore && windowSizeLast?.width !== windowSize?.width) {
            const fillGaps = readingsInfo?.fillGaps;

            UiHelper.SetLoadingSemaphore(dispatch, 1);
            dispatch(setReadingsInfo({ readingsInfo: { fillGaps: !fillGaps } }));

            setTimeout(() => {
                dispatch(setReadingsInfo({ readingsInfo: { fillGaps } }));
                UiHelper.SetLoadingSemaphore(dispatch, -1);
            }, 1000);

            setWindowSizeLast(windowSize);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowSize]);

    return !contributingEvent ? null : (
        <div className={styleGuide.eventDetail}>
            <div className={styleGuide.whiteBackground} id={'eventScrollTop'}>
                <Header
                    scrollActive1={scrollActive1}
                    insightAttributes={insightAttributes}
                    timeRangeData={timeRangeData}
                />

                <Body
                    translate={translate}
                    anI18Nextlib={anI18Nextlib}
                    scrollActive1={scrollActive1}
                    insight={insight}
                    contributingEvent={contributingEvent}
                    insightAttributes={insightAttributes}
                    typeStyle={typeStyle}
                    bolusDataPre={bolusDataPre}
                    bolusDataPost={bolusDataPost}
                    bloodGlucoseDataAttributes={bloodGlucoseDataAttributes}
                    insulinDeliveryDataAttributes={insulinDeliveryDataAttributes}
                    marginLeft={marginLeft}
                    marginRight={marginRight}
                    xAxisTicks={xAxisTicks}
                />
            </div>
        </div>
    );
}

export default ContributingEventDetail;
