import { ILearningMaterialState } from '../../learningMaterial.types';
import DateTimeHelper from '../../../../helpers/DateTimeHelper';

const fetchLearningMaterialResourcesFulfilled = (state: ILearningMaterialState, action: any) => {
    if (action.payload) {
        state.resource = {
            id: action.payload.dashboardState.dashboardEnd,
            data: action.payload.data.item?.resources,
            expires: DateTimeHelper.AddSecs(DateTimeHelper.GetIsoNow(), 60),
        };
    }
};

export default fetchLearningMaterialResourcesFulfilled;
