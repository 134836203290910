import { PayloadAction } from '@reduxjs/toolkit';

import { IInsightsState, IReadingsInfo } from './insights.types';
import { insightsInitialState } from './insights.initialState';

import { DataItemsInsight } from '../../model/dataItemsInsight';
import UtilityHelper from '../../helpers/UtilityHelper';
import InsightHelper from '../../helpers/InsightHelper';

const appReducers = {
    resetCachedInsights(state: IInsightsState) {
        state.cachedInsights = insightsInitialState.cachedInsights;
    },
    resetCurrentReadingCombo(state: IInsightsState) {
        state.currentReadingCombo = insightsInitialState.currentReadingCombo;
    },
    resetInsightIds(state: IInsightsState) {
        state.insightIds = insightsInitialState.insightIds;
    },
    setReadingsInfo: {
        reducer: (state: IInsightsState, action: PayloadAction<{ readingsInfo: IReadingsInfo }>) => {
            if (
                !UtilityHelper.IsNull(action.payload?.readingsInfo.fillGaps) &&
                state.readingsInfo?.fillGaps !== action.payload?.readingsInfo.fillGaps
            ) {
                state.currentReadingCombo.readingsCombo = action.payload?.readingsInfo.fillGaps
                    ? state.currentReadingCombo.readingsComboNoGaps
                    : state.currentReadingCombo.readingsComboGaps;
            }

            state.readingsInfo = {
                ...state.readingsInfo,
                ...action.payload?.readingsInfo,
            };
        },
        prepare: ({ readingsInfo }: { readingsInfo: IReadingsInfo }) => ({
            payload: {
                readingsInfo,
            },
        }),
    },
    setInsightId: {
        reducer: (state: IInsightsState, action: PayloadAction<{ insightId: string }>) => {
            state.insightId = action.payload.insightId;
        },
        prepare: ({ insightId }: { insightId: string }) => ({
            payload: {
                insightId,
            },
        }),
    },
    setContributingEventMetaData: {
        reducer: (
            state: IInsightsState,
            action: PayloadAction<{
                contributingEventId: string;
                contributingEventBeg: string;
                contributingEventEnd: string;
            }>
        ) => {
            state.contributingEventId = action.payload.contributingEventId;
            state.contributingEventBeg = action.payload.contributingEventBeg;
            state.contributingEventEnd = action.payload.contributingEventEnd;
            state.currentReadingCombo = {};
        },
        prepare: ({
            contributingEventId,
            contributingEventBeg,
            contributingEventEnd,
        }: {
            contributingEventId: string;
            contributingEventBeg: string;
            contributingEventEnd: string;
        }) => ({
            payload: {
                contributingEventId,
                contributingEventBeg,
                contributingEventEnd,
            },
        }),
    },
    setInsightsData: {
        reducer: (state: IInsightsState, action: PayloadAction<DataItemsInsight>) => {
            const insightIds: string[] = [];

            if (action.payload.totalCount > 0) {
                InsightHelper.PopulateInsightData(state, action.payload.items, insightIds);
            }

            state.insightIds = insightIds;
        },
        prepare: ({ totalCount, items }: DataItemsInsight) => ({
            payload: { totalCount, items },
        }),
    },
};

export default appReducers;
