import { PayloadAction } from '@reduxjs/toolkit';

import { IUIState, IWindowSize } from './ui.types';

const uiReducers = {
    setCarouselIndex: {
        reducer: (state: IUIState, action: PayloadAction<{ index: number }>) => {
            state.carouselIndex = action.payload.index;
        },
        prepare: ({ index }: { index: number }) => ({
            payload: {
                index,
            },
        }),
    },
    setIsMenuOpen: {
        reducer: (state: IUIState, action: PayloadAction<{ isMenuOpen: boolean }>) => {
            state.isMenuOpen = action.payload.isMenuOpen;
        },
        prepare: ({ isMenuOpen }: { isMenuOpen: boolean }) => ({
            payload: {
                isMenuOpen,
            },
        }),
    },
    setIsMenuVisible: {
        reducer: (state: IUIState, action: PayloadAction<{ isMenuVisible: boolean }>) => {
            state.isMenuVisible = action.payload.isMenuVisible;
        },
        prepare: ({ isMenuVisible }: { isMenuVisible: boolean }) => ({
            payload: {
                isMenuVisible,
            },
        }),
    },
    setWindowSize: {
        reducer: (state: IUIState, action: PayloadAction<IWindowSize>) => {
            state.windowSize.height = action.payload.height;
            state.windowSize.width = action.payload.width;
        },
        prepare: ({ height, width }: IWindowSize) => ({
            payload: {
                height,
                width,
            },
        }),
    },
    setAnchorAllReports: {
        reducer: (state: IUIState, action: PayloadAction<{ anchorAllReports: string }>) => {
            state.anchorAllReports = action.payload.anchorAllReports;
        },
        prepare: ({ anchorAllReports }: { anchorAllReports: string }) => ({
            payload: {
                anchorAllReports,
            },
        }),
    },
    setAnchorEvent: {
        reducer: (state: IUIState, action: PayloadAction<{ anchorEvent: string }>) => {
            state.anchorEvent = action.payload.anchorEvent;
        },
        prepare: ({ anchorEvent }: { anchorEvent: string }) => ({
            payload: {
                anchorEvent,
            },
        }),
    },
    setAnchorInsight: {
        reducer: (state: IUIState, action: PayloadAction<{ anchorInsight: string }>) => {
            state.anchorLoyaltyCongrats = undefined;
            state.anchorLoyaltyInsulin = undefined;
            state.anchorInsight = action.payload.anchorInsight;
        },
        prepare: ({ anchorInsight }: { anchorInsight: string }) => ({
            payload: {
                anchorInsight,
            },
        }),
    },
    setAnchorLoyaltyCongrats: {
        reducer: (state: IUIState, action: PayloadAction<{ anchorLoyaltyCongrats: string }>) => {
            state.anchorLoyaltyCongrats = action.payload.anchorLoyaltyCongrats;
            state.anchorLoyaltyInsulin = undefined;
            state.anchorInsight = undefined;
        },
        prepare: ({ anchorLoyaltyCongrats }: { anchorLoyaltyCongrats: string }) => ({
            payload: {
                anchorLoyaltyCongrats,
            },
        }),
    },
    setAnchorLoyaltyInsulin: {
        reducer: (state: IUIState, action: PayloadAction<{ anchorLoyaltyInsulin: string }>) => {
            state.anchorLoyaltyCongrats = undefined;
            state.anchorLoyaltyInsulin = action.payload.anchorLoyaltyInsulin;
            state.anchorInsight = undefined;
        },
        prepare: ({ anchorLoyaltyInsulin }: { anchorLoyaltyInsulin: string }) => ({
            payload: {
                anchorLoyaltyInsulin,
            },
        }),
    },
    setShowLegend: {
        reducer: (state: IUIState, action: PayloadAction<{ showLegend: boolean }>) => {
            state.showLegend = action.payload.showLegend;
        },
        prepare: ({ showLegend }: { showLegend: boolean }) => ({
            payload: {
                showLegend,
            },
        }),
    },
};

export default uiReducers;
