import pluralize from 'pluralize';

import UiHelper from './UiHelper';
import UtilityHelper from './UtilityHelper';

import { ITranslator } from '../types';

export default class StringHelper {
    private static currencyFormatObj = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        currencyDisplay: 'symbol',
    });

    private static decimalFormatObj = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
    });

    private static integerFormatObj = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 0,
    });

    public static NumberFormatter = (inNum: number): string => {
        return isNaN(inNum) ? '' : StringHelper.decimalFormatObj.format(inNum);
    };

    private static numberFormatA = (inNum: number): string => {
        const utcOffsetString = `${inNum}`;
        let utcOffsetParsedfileName = utcOffsetString;

        if (utcOffsetString.includes('.')) {
            const utcOffsetStringFlds = utcOffsetString.split('.').filter((e: string) => e.trim().length > 0);
            const utcOffsetStringFldsFormatted = utcOffsetStringFlds.map((e: string, idx: number) => {
                const checkNumber = Number(e) < 10 ? '0' : '';
                const checkString = e === '5' ? '30' : e;
                return idx === 0 ? `${checkNumber}${e}` : checkString;
            });
            utcOffsetParsedfileName = utcOffsetStringFldsFormatted.join(':');
        }

        const utcOffsetParsed = (inNum === 0 ? 'Z' : utcOffsetParsedfileName).replace('-', '').replace('+', '');

        const inNumCondtion = inNum < 0 ? '-' : '';
        return `${inNum > 0 ? '+' : inNumCondtion}${utcOffsetParsed}`;
    };

    private static numberFormatB = (inNum: number, isCurrency: boolean, isInteger: boolean): string => {
        let fmt: Intl.NumberFormat;

        if (isCurrency) {
            fmt = StringHelper.currencyFormatObj;
        } else if (isInteger) {
            fmt = StringHelper.integerFormatObj;
        } else {
            fmt = StringHelper.decimalFormatObj;
        }

        return fmt.format(inNum);
    };

    public static NumberFormat = (
        inNum: number,
        isCurrency = false,
        isInteger = false,
        isUtcOffsetValue = false
    ): string => {
        if (isNaN(inNum)) {
            return '';
        } else if (isUtcOffsetValue) {
            return StringHelper.numberFormatA(inNum);
        } else {
            return StringHelper.numberFormatB(inNum, isCurrency, isInteger);
        }
    };

    public static Pluralize = (input: number, label = '', showCount = true): string => {
        const partA: string = showCount ? `${StringHelper.NumberFormat(input, false, true)} ` : '';
        const partB: string = label.length > 0 ? pluralize(label, input, false) : '';

        return `${partA}${partB}`;
    };

    public static NumberEnglishOrdinalSuffix = (input: number) => {
        const mod10 = input % 10;
        const last2 = Number(`00${input}`.slice(-2));
        let suffix = 'th';

        if (mod10 === 1 && last2 !== 11) {
            suffix = 'st';
        } else if (mod10 === 2 && last2 !== 12) {
            suffix = 'nd';
        } else if (mod10 === 3 && last2 !== 13) {
            suffix = 'rd';
        }

        return suffix;
    };

    public static GetDeltaPct = (
        anI18Nextlib: any,
        value: number,
        translate: ITranslator
    ): {
        prefix: string;
        delta: string;
    } => {
        const valueAbs = Math.abs(value ?? 0);
        const dictSection = 'delta.';
        let prefix = '';
        let delta = translate(`${dictSection}noChange`);

        if (valueAbs > 0) {
            const conditionValueAbs = `${valueAbs}`.substring(0, 1) === '8' || valueAbs === 18 ? 'an' : 'a';
            const keyValue = `${dictSection}${conditionValueAbs}`;
            prefix = `${UiHelper.Translate(anI18Nextlib, keyValue)} `;
            const dictSectionValue = `${dictSection}${value > 0 ? 'increase' : 'decrease'}`;
            delta = `${valueAbs}% ${translate(dictSectionValue)}`;
        }

        return {
            prefix,
            delta,
        };
    };

    public static IsEmptyString = (input: any): boolean =>
        UtilityHelper.IsNull(input) || `${input}`.trim().length === 0;

    public static GetCheckBoxState = (currState: boolean): string => (currState ? 'on' : 'off');
}
