import { IAppState } from '../../app.types';
import ConstantsHelper from '../../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../../helpers/UtilityHelper';

const restoreStateFromLocalStorageFulfilled = (state: IAppState, action: any) => {
    state.allLoaded = true;
    state.calendarDate = action.payload.calendarDate;
    state.reloadInProgress = false;
    state.idTag = action.payload.idTag;

    UtilityHelper.SettingSet(ConstantsHelper.IntersessionKeys.signOutCanProceed, '0'); // Resets sign out flag
};

export default restoreStateFromLocalStorageFulfilled;
