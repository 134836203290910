import { useTranslation } from 'react-i18next';
import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { IInsight3 } from '../../../types';
import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import arrowByValueRange from '../../common/arrowByValueRange';
import StringHelper from '../../../helpers/StringHelper';
import UiHelper from '../../../helpers/UiHelper';
import { RootState } from '../../../store/store';

function InsightSummaryGlucoseTrends({ summary }: Readonly<{ summary: IInsight3 }>) {
    const { t } = useTranslation();

    const anI18Nextlib = useSelector((state: RootState) => state.app.anI18Nextlib);

    const translate = (key: string, subs?: any) => UiHelper.Translate(anI18Nextlib, key, subs);

    const { prefix, delta } = StringHelper.GetDeltaPct(anI18Nextlib, summary.timeInRangePctDelta, translate);

    return (
        <div className={clsx(styleGeneral.main, styleGuide.insightSummary)}>
            <div className={styleGuide.whiteBackground}>
                <div className={styleGuide.headerSummary}>
                    <div className={styleGuide.glucoseTrends}>
                        <div className={clsx(styleGeneral.table, styleGuide.row1)}>
                            <div className={clsx(styleGeneral.row)}>
                                <span className={clsx(styleGeneral.cell, styleGuide.left)}>
                                    <span className={clsx(styleGeneral.cell, styleGuide.top)}>
                                        <div className={styleGuide.titleAlt}>
                                            <div>{t('glucoseTrends')}</div>
                                        </div>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className={clsx(styleGeneral.table, styleGuide.row2)}>
                            <div className={clsx(styleGeneral.flex)}>
                                <span className={clsx(styleGeneral.cell, styleGuide.left, styleGuide.flexLeft)}>
                                    {Math.abs(summary.timeInRangePctDelta)}%
                                </span>
                                <span className={clsx(styleGeneral.cell, styleGuide.right, styleGuide.flexRight)}>
                                    <span className={clsx(styleGuide.top)}>
                                        <span className={clsx(styleGuide.icon, styleGeneral.flex)}>
                                            <img
                                                src={arrowByValueRange(summary.timeInRangePctDelta)}
                                                alt={t('altIncreaseArrow')}
                                            />
                                        </span>
                                    </span>
                                    <span className={clsx(styleGuide.bottom, styleGuide.bottomPreLine)}>
                                        {t('sinceLastWeek', { tag: summary.prevWeekLabel })}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className={clsx(styleGeneral.table, styleGuide.row3)}>
                            <div className={styleGeneral.row}>
                                <span className={clsx(styleGeneral.cell, styleGuide.comparison)}>
                                    {t('prevWeekComparisonA', {
                                        inRange: summary.timeInRangePct - summary.timeInRangePctDelta,
                                        prefix,
                                    })}
                                    <span className={styleGuide.delta}>{delta}</span>
                                    {t('prevWeekComparisonB')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InsightSummaryGlucoseTrends;
