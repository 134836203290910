import { createSlice } from '@reduxjs/toolkit';

import { authenticationInitialState } from './authentication.initialState';
import authenticationReducers from './authentication.reducers';

import { resetStore } from '../root.actions';

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: authenticationInitialState,
    reducers: authenticationReducers,
    extraReducers: (builder) => {
        builder.addCase(resetStore, () => authenticationInitialState);
    },
});

export const { setOktaData } = authenticationSlice.actions;

export default authenticationSlice.reducer;
