import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { isNil } from 'lodash';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';

import { IDayToFlag, IInsight3 } from '../../../types';
import { CelebrationDay } from '../../../model/celebrationDay';
import ConstantsHelper from '../../../helpers/ConstantsHelper';
import iconTravelDayLight from '../../../assets/images/icon-airplane-light.png';
import iconTravelDayDark from '../../../assets/images/icon-airplane-dark.svg';
import InsightHelper from '../../../helpers/InsightHelper';
import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import { RootState } from '../../../store/store';

function InsightSummaryDaysInRange({ summary }: Readonly<{ summary: IInsight3 }>) {
    const { t } = useTranslation();

    const anI18Nextlib = useSelector((state: RootState) => state.app.anI18Nextlib);

    const summaryLocal = {
        celebrationDays: [] as CelebrationDay[],
        goodPctThreshold: 0,
        lowEgvMgDlThreshold: 0,
        highEgvMgDlThreshold: 0,
        ...summary,
    };

    const celebrationDays = summaryLocal.celebrationDays ?? [];
    const count = celebrationDays.filter((d) => d.percentage >= summaryLocal.goodPctThreshold).length;
    const flaggedDay: IDayToFlag[] = InsightHelper.GetFlaggedDaysForSummary(anI18Nextlib, summaryLocal);

    const dayData = flaggedDay.map((e) => {
        const celebrationDay = celebrationDays.find((c) => c.date === e.timestamp);

        return {
            ...e,
            ...celebrationDay,
            isGoodDay: celebrationDay?.percentage >= summaryLocal.goodPctThreshold,
        };
    });

    const getTileClass = (isGoodDay: boolean, hasTimezoneChangeInner: boolean, percentage: number) => {
        const isPercentageNA = isNil(percentage) || percentage < 0;

        if (isGoodDay) {
            return styleGuide.dayBoxGreen;
        } else if (isPercentageNA && hasTimezoneChangeInner) {
            return styleGuide.dayBoxGrayTravel;
        } else {
            return styleGuide.dayBoxGray;
        }
    };

    const getTravelDayIconSrc = (percentage: number) => {
        const isBetweenZeroAndGoodPercentThreshold = percentage >= 0 && percentage < summaryLocal.goodPctThreshold;

        return isBetweenZeroAndGoodPercentThreshold ? iconTravelDayDark : iconTravelDayLight;
    };

    const hasTimezoneChange = dayData.filter((d) => d.hasTimezoneChange).length > 0;

    return (
        <div className={clsx(styleGeneral.main, styleGuide.insightSummary)}>
            <div className={clsx(styleGuide.whiteBackground, styleGuide.card)}>
                <div className={styleGuide.headerSummary}>
                    <div className={styleGuide.dayCount}>
                        <div className={clsx(styleGeneral.table, styleGuide.row1)}>
                            <div className={clsx(styleGeneral.row)}>
                                <span className={clsx(styleGeneral.cell, styleGuide.left)}>
                                    <span className={clsx(styleGeneral.cell, styleGuide.top)}>
                                        <div className={styleGuide.titleAlt}>
                                            <div>{t('daysInRange')}</div>
                                        </div>
                                        <div className={styleGuide.subTitle}>
                                            {t('daysInRangeWithinA')}{' '}
                                            <b>
                                                {t('daysInRangeWithinB', {
                                                    from: summaryLocal.lowEgvMgDlThreshold,
                                                    to: summaryLocal.highEgvMgDlThreshold,
                                                    units: ConstantsHelper.ReadingsUnit,
                                                })}
                                            </b>{' '}
                                            {t('daysInRangeWithinC')}{' '}
                                            <b>
                                                {t('daysInRangeWithinD', {
                                                    pct: summaryLocal.goodPctThreshold,
                                                })}
                                            </b>{' '}
                                            {t('daysInRangeWithinE')}
                                        </div>
                                    </span>
                                </span>
                            </div>
                        </div>

                        <div
                            className={clsx(styleGeneral.table, styleGuide.row2, {
                                [styleGuide.noTimezoneChange]: !hasTimezoneChange,
                            })}
                        >
                            <div className={styleGeneral.row}>
                                <span className={clsx(styleGeneral.cell, styleGuide.left, styleGuide.dayBoxGreen2)}>
                                    {count}
                                </span>
                                <span className={clsx(styleGeneral.cell, styleGuide.right)}>
                                    <div className={clsx(styleGuide.rightTable)}>
                                        <span className={clsx(styleGuide.top, styleGuide.uppercase)}>
                                            {t('dateTime.day', { count })}
                                        </span>
                                        <span className={clsx(styleGuide.bottom)}>{t('inRange')}</span>
                                    </div>
                                </span>
                            </div>
                        </div>

                        <div className={clsx(styleGeneral.table, styleGuide.row3, styleGuide.dayTilesRow)}>
                            <Grid className={clsx(styleGuide.dayTiles)} item xs={12}>
                                <div>
                                    <div>
                                        {hasTimezoneChange && (
                                            <Grid
                                                className={styleGuide.dayTitlesContainer}
                                                container
                                                data-testid="travel_day_labels_container"
                                                justifyContent="space-between"
                                                spacing={0}
                                                wrap="nowrap"
                                            >
                                                {dayData.map((d, idx) => (
                                                    <Grid
                                                        className={styleGuide.dayTileColumn}
                                                        data-testid={`${d.dayName}_travel_day_label_container`}
                                                        item
                                                        key={`flaggedDay${idx}`}
                                                        sm="auto"
                                                    >
                                                        <span
                                                            key={`travelDay${idx}`}
                                                            className={clsx(
                                                                styleGeneral.cell,
                                                                styleGuide.tile,
                                                                styleGuide.dayBoxBlankShort
                                                            )}
                                                        >
                                                            {d.hasTimezoneChange && t('travelDay')}
                                                        </span>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        )}

                                        <Grid
                                            className={styleGuide.dayTitlesContainer}
                                            container
                                            justifyContent="space-between"
                                            spacing={0}
                                            wrap="nowrap"
                                        >
                                            {dayData.map((d, idx) => (
                                                <Grid
                                                    className={styleGuide.dayTileColumn}
                                                    data-testid={`${d.dayName}_day_tile_container`}
                                                    item
                                                    key={`flaggedDay${idx}`}
                                                    sm="auto"
                                                >
                                                    <span
                                                        className={clsx(
                                                            styleGeneral.cell,
                                                            styleGuide.tile,
                                                            getTileClass(d.isGoodDay, d.hasTimezoneChange, d.percentage)
                                                        )}
                                                        data-testid={`${d.dayName}_day_tile`}
                                                    >
                                                        {d.hasTimezoneChange ? (
                                                            <img
                                                                alt={t('travelDay')}
                                                                className={styleGuide.travelIcon}
                                                                data-testid={`${d.dayName}_icon`}
                                                                src={getTravelDayIconSrc(d.percentage)}
                                                            />
                                                        ) : (
                                                            <>{d.dayLetter}</>
                                                        )}
                                                    </span>
                                                </Grid>
                                            ))}
                                        </Grid>

                                        <Grid
                                            className={styleGuide.dayTitlesContainer}
                                            container
                                            justifyContent="space-between"
                                            spacing={0}
                                            wrap="nowrap"
                                        >
                                            {dayData.map((d, idx) => (
                                                <Grid
                                                    className={styleGuide.dayTileColumn}
                                                    data-testid={`${d.dayName}_percentage_label_container`}
                                                    item
                                                    key={`flaggedDay${idx}`}
                                                    sm="auto"
                                                >
                                                    <span
                                                        key={`flaggedDayPct${idx}`}
                                                        className={clsx(
                                                            styleGeneral.cell,
                                                            styleGuide.tile,
                                                            styleGuide.dayBoxBlank,
                                                            styleGuide.tiny,
                                                            d.isGoodDay && styleGuide.bold
                                                        )}
                                                    >
                                                        {d.percentage < 0 || isNaN(d.percentage)
                                                            ? t('notAvailable')
                                                            : `${Math.round(d.percentage)}%`}
                                                    </span>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InsightSummaryDaysInRange;
