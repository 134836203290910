import { parse } from 'node-html-parser';

import { IAppState } from '../../app.types';
import { ReleaseNotesJSONDataItem } from '../../../../model/releaseNotesJSONDataItem';
import ConstantsHelper from '../../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../../helpers/UtilityHelper';
import AnI18NextLibHelper from '../../../../helpers/AnI18NextLibHelper';

const fetchBackEndIdFulfilled = (state: IAppState, { payload }: any) => {
    const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchBackEndId);
    if (callKey) {
        delete state.activeHttpCalls[callKey];
    }
    const dataRecs = parse(payload, {
        lowerCaseTagName: true,
        comment: false,
        blockTextElements: {
            script: false,
            style: false,
        },
    })
        ?.getElementsByTagName('tr')
        ?.map((trEntry) => {
            const labels = trEntry.getElementsByTagName('th');
            const values = trEntry.getElementsByTagName('td');
            return labels?.length > 0 && values?.length > 0
                ? `${labels[0].innerText.toLowerCase()}=${values[0].innerText}`
                : '';
        })
        ?.filter((line) => line?.trim()?.length > 0);
    const data: ReleaseNotesJSONDataItem = {
        cICDBuildPipelineID: `${dataRecs.find((line) => line.indexOf('ci/cd build pipeline id=') > -1)}=`.split('=')[1],
        cICDReleasePipelineID: `${dataRecs.find((line) => line.indexOf('ci/cd release pipeline id=') > -1)}=`.split(
            '='
        )[1],
        notes: [],
        repositoryBranch: undefined,
        storageAccess: 'ok',
        version: `${dataRecs.find((line) => line.indexOf('version=') > -1)}=`.split('=')[1],
    };
    state.versionBackEnd = [
        data.version.length > 0 ? data.version : '--',
        data.cICDBuildPipelineID,
        data.cICDReleasePipelineID,
    ]
        .filter((entry) => entry.length > 0)
        .join(' / ');
    state.errors[callKey] = {
        level: ConstantsHelper.ErrorLevels.success,
        message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.backEndId'),
    };
    state.loadingSemaphore--;
};

export default fetchBackEndIdFulfilled;
