import { PayloadAction } from '@reduxjs/toolkit';

import { IPatient, IProfileState } from './profile.types';

const profileReducers = {
    updatePatient: {
        reducer: (state: IProfileState, action: PayloadAction<{ patient: Partial<IPatient> }>) => {
            state.data = {
                ...state.data,
                ...action.payload.patient,
            };
        },
        prepare: ({ patient }: { patient: Partial<IPatient> }) => ({
            payload: {
                patient,
            },
        }),
    },
};

export default profileReducers;
