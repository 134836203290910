import { ILearningMaterialState } from '../../learningMaterial.types';
import DateTimeHelper from '../../../../helpers/DateTimeHelper';

const fetchLearningMaterialOp5ProgressFulfilled = (state: ILearningMaterialState, action: any) => {
    if (action.payload) {
        state.op5Progress = {
            id: action.payload.dashboardState.dashboardEnd,
            data: action.payload.data.item.progress,
            expires: DateTimeHelper.AddSecs(DateTimeHelper.GetIsoNow(), 60),
        };
    }
};

export default fetchLearningMaterialOp5ProgressFulfilled;
