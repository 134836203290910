import { useTranslation } from 'react-i18next';
import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import InsightSummaryCarousel from './summary/InsightSummaryCarousel';
import LoyaltyFrame from './loyalty/loyaltyFrame';

import { Insight3, Insight3GlucoseTrend } from '../../model/models';
import { ICallback } from '../../types';
import { IInsight } from '../../store/insights/insights.types';
import { RootState } from '../../store/store';
import InsightsByPattern from '../../components/dashboard/insightsByPattern';
import iconNoInsights from '../../assets/images/no-insights.svg';
import styleGuide from '../../styles/styleGuide.module.scss';

function NoInsights() {
    const { t } = useTranslation();

    return (
        <div className={styleGuide.dashboardInsightsOff}>
            <img src={iconNoInsights} className={clsx(styleGuide.icon)} alt={t('noInsights')} />
            <div className={styleGuide.title}>{t('noInsights')}</div>
            <div className={styleGuide.body}>
                {t('noInsightsForThisWeek')}
                <br></br>
                <br></br>
                {t('seeYouNextWeek')}
            </div>
        </div>
    );
}

function Insights({
    insights,
    scrollActive1,
    scrollActive2,
    cbClickFn,
}: Readonly<{
    insights: IInsight[];
    scrollActive1: boolean;
    scrollActive2: boolean;
    cbClickFn: ICallback<void>;
}>) {
    return (
        <div className={styleGuide.dashboardInsightsOn}>
            <InsightsByPattern
                key={`insights${1}`}
                scrollActive1={scrollActive1}
                scrollActive2={scrollActive2}
                insights={insights}
                testId={`insights${1}`}
                cbClickFn={cbClickFn}
            />
        </div>
    );
}

function InsightsFrame({
    scrollActive1,
    scrollActive2,
    insightIds,
    summary,
    summaryGlucoseTrend,
    cbClickFn,
    cbCarouselSetIndexFn,
    cbLoyaltyCongrats,
    cbLoyaltyInsulin,
}: Readonly<{
    scrollActive1: boolean;
    scrollActive2: boolean;
    insightIds: string[];
    summary: Insight3;
    summaryGlucoseTrend: Insight3GlucoseTrend;
    cbClickFn: ICallback<void>;
    cbCarouselSetIndexFn: ICallback<void>;
    cbLoyaltyCongrats?: any;
    cbLoyaltyInsulin?: any;
}>) {
    const loadingSemaphore = useSelector((state: RootState) => state.app.loadingSemaphore);
    const cachedInsights = useSelector((state: RootState) => state.insights.cachedInsights);

    const insights = insightIds?.map((insightId: string) => cachedInsights[insightId]);

    if (loadingSemaphore !== 0) {
        return null;
    }

    return (
        <div className={styleGuide.solidBg} data-testid="insights-array">
            <InsightSummaryCarousel
                scrollActive2={scrollActive2}
                summary={summary}
                summaryGlucoseTrend={summaryGlucoseTrend}
                setIndex={cbCarouselSetIndexFn}
            />

            <LoyaltyFrame
                scrollActive2={scrollActive2}
                cbLoyaltyCongrats={cbLoyaltyCongrats}
                cbLoyaltyInsulin={cbLoyaltyInsulin}
            />

            {insights.length ? (
                <Insights
                    insights={insights}
                    scrollActive1={scrollActive1}
                    scrollActive2={scrollActive2}
                    cbClickFn={cbClickFn}
                />
            ) : (
                <NoInsights />
            )}
        </div>
    );
}

export default InsightsFrame;
