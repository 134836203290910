import { PayloadAction } from '@reduxjs/toolkit';

import { IAuthenticationState, IOktaData } from './authentication.types';

const authenticationReducers = {
    setOktaData: {
        reducer: (state: IAuthenticationState, action: PayloadAction<IOktaData>) => {
            state.oktaData = { ...action.payload };
        },
        prepare: ({ access, aud, isUserDefined, expires, id, userName }: IOktaData) => ({
            payload: { access, aud, isUserDefined, expires, id, userName },
        }),
    },
};

export default authenticationReducers;
