import { IDashboardState } from '../../dashboard.types';

const fetchDashboardSummaryFulfilled = (state: IDashboardState, action: any) => {
    if (action.payload) {
        state.currentSummary = {
            ...action.payload.data.item,
            celebrationDays: action.payload.data.item.celebrationDays?.map(
                (dayEntry: { hasTimezoneChange: boolean; percentage: string }) => {
                    const hasTimezoneChange = dayEntry.hasTimezoneChange;

                    return {
                        ...dayEntry,
                        percentage: dayEntry.percentage ?? 0,
                        hasTimezoneChange,
                    };
                }
            ),
        };
    }
};

export default fetchDashboardSummaryFulfilled;
