import { createSlice } from '@reduxjs/toolkit';

import { learningMaterialInitialState } from './learningMaterial.initialState';
import fetchLearningMaterialResourcesFulfilled from './extraReducers/fetchLearningMaterialResources/fulfilled.extraReducer';
import fetchLearningMaterialOp5ProgressFulfilled from './extraReducers/fetchLearningMaterialOp5Progress/fulfilled.extraReducer';
import { fetchLearningMaterialOp5Progress, fetchLearningMaterialResources } from './learningMaterial.thunks';

import { resetStore } from '../root.actions';

const learningMaterial = createSlice({
    name: 'learningMaterial',
    initialState: learningMaterialInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(resetStore, () => learningMaterialInitialState)
            .addCase(fetchLearningMaterialResources.fulfilled, fetchLearningMaterialResourcesFulfilled)
            .addCase(fetchLearningMaterialOp5Progress.fulfilled, fetchLearningMaterialOp5ProgressFulfilled);
    },
});

export default learningMaterial.reducer;
