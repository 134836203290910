import React, { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { IMenuEntry } from '../../types';
import styleGeneral from '../../styles/general.module.scss';
import styleGuide from '../../styles/styleGuide.module.scss';

interface IMenuLabelProps {
    menuEntry: IMenuEntry;
}

function MenuLabel({ menuEntry }: Readonly<IMenuLabelProps>) {
    const { t } = useTranslation();

    return (
        <span className={clsx(styleGuide.H3BlackLeft, styleGeneral.menuItem)}>
            <div className={styleGeneral.iconFrame}>
                {menuEntry?.icon && (
                    <img src={menuEntry.icon} alt={t(menuEntry?.label)} className={styleGeneral.icon} />
                )}
            </div>
            <div className={styleGeneral.labelFrame}>{t(menuEntry?.label)}</div>
        </span>
    );
}

export default MenuLabel;
