import React, { useEffect, ReactNode, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';

import UiHelper from 'src/helpers/UiHelper';
import { Patient } from 'src/model/patient';

import { RootState } from '../../store/store';
import { pollProfileActionCreator } from '../../store/app/app.slice';
import { setIsMenuVisible } from '../../store/ui/ui.slice';
import { IPatient } from '../../store/profile/profile.types';
import SystemHelper from '../../helpers/SystemHelper';
import EulaAndConfAgr from '../common/contentWrappers/EulaAndConfAgr';
import SmsNumber from '../common/contentWrappers/SmsNumber';
import { saveAndConfirmSmsNumber, updateProfile } from '../../store/profile/profile.thunks';

const Agreements = memo(function Agreements({ children }: Readonly<{ children?: ReactNode }>): JSX.Element {
    const loadingSemaphore = useSelector((state: RootState) => state.app.loadingSemaphore);
    const profileData = useSelector((state: RootState) => state.profile.data);

    const dispatch = useDispatch();
    const history = useHistory();
    const isPauseItEnabled = SystemHelper.IsPauseItEnabled();
    const patient: IPatient = profileData ?? ({} as IPatient);

    const oktaAuthState = useOktaAuth()?.authState;

    useEffect(() => {
        if (!profileData.isEnrolled) {
            const oktaData = SystemHelper.ParseOktaData(oktaAuthState, isPauseItEnabled);

            UiHelper.FetchPatient(dispatch, UiHelper.SaveOktaData(dispatch, oktaData));
            UiHelper.FetchClinics(dispatch, UiHelper.SaveOktaData(dispatch, oktaData));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileData.isEnrolled]);

    const showEulaAndConfAgr = isPauseItEnabled && !(patient.eulaAccepted && patient.confidentialityAgreementAccepted);

    const showVerifySmsNumber =
        isPauseItEnabled &&
        (loadingSemaphore || !patient.smsNumberStatus) &&
        patient.eulaAccepted &&
        patient.confidentialityAgreementAccepted &&
        patient.deviceClass === Patient.DeviceClassEnum.Omnipod5;

    const newIsMenuVisible = !showVerifySmsNumber && !showEulaAndConfAgr;
    useEffect(() => {
        dispatch(setIsMenuVisible({ isMenuVisible: newIsMenuVisible }));
    }, [dispatch, newIsMenuVisible]);

    if (showEulaAndConfAgr) {
        const eulaAndConfAgrCb = () =>
            dispatch(updateProfile({ eulaAccepted: true, confidentialityAgreementAccepted: true }));
        return <EulaAndConfAgr closeCb={eulaAndConfAgrCb} />;
    }

    if (showVerifySmsNumber) {
        const verifySmsNumberCb = (smsNumber: string) => {
            dispatch(saveAndConfirmSmsNumber({ smsNumber }));
            dispatch(pollProfileActionCreator({ active: true }));
            history.replace('/');
        };

        const confirmedSmsNumberCb = () => {
            dispatch(pollProfileActionCreator({ active: false }));
            history.replace('/');
        };

        return <SmsNumber verifyCb={verifySmsNumberCb} confirmedCb={confirmedSmsNumberCb} />;
    }

    return <>{children}</>;
});

export default Agreements;
