import { IAppState } from '../../app.types';
import ConstantsHelper from '../../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../../helpers/UtilityHelper';
import UiHelper from '../../../../helpers/UiHelper';
import AnI18NextLibHelper from '../../../../helpers/AnI18NextLibHelper';

const sendFeedbackPending = (state: IAppState, action: any) => {
    state.loadingSemaphore++;

    const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.sendFeedback, [
        action.meta?.arg?.insightId,
        action.meta?.arg?.feedbackValue,
        action.meta?.arg?.insightIdParent,
    ]);

    state.activeHttpCalls[callKey] = true;

    const message = AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.sendingFeedback');

    state.message = message;

    state.errors[callKey] = UiHelper.CreateErrorMessage({
        level: ConstantsHelper.ErrorLevels.info,
        message,
        sticky: false,
        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.feedback'),
    });
};

export default sendFeedbackPending;
