import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, ReactNode, memo } from 'react';

import { RootState } from '../../store/store';
import { setIsMenuVisible } from '../../store/ui/ui.slice';
import { updateProfile } from '../../store/profile/profile.thunks';
import Welcome from '../common/contentWrappers/Welcome';
import Disclaimer from '../common/contentWrappers/Disclaimer';

const Onboarding = memo(function Onboarding({ children }: Readonly<{ children?: ReactNode }>): JSX.Element {
    const profileData = useSelector((state: RootState) => state.profile.data);

    const dispatch = useDispatch();
    const history = useHistory();

    const showWelcomeDisclaimer = !profileData.isUnenrolled && profileData.isEnrolled;
    const showWelcome = !profileData.welcomeSeen && showWelcomeDisclaimer;
    const showDisclaimer = !profileData.disclaimerSeen && profileData.welcomeSeen && showWelcomeDisclaimer;
    const newIsMenuVisible = !showWelcome && !showDisclaimer;

    useEffect(() => {
        dispatch(setIsMenuVisible({ isMenuVisible: newIsMenuVisible }));
    }, [dispatch, newIsMenuVisible]);

    if (showWelcome) {
        const welcomeSeenCb = () => {
            dispatch(updateProfile({ welcomeSeen: true }));
            history.replace('/');
        };

        return <Welcome closeCb={welcomeSeenCb} />;
    }

    const disclaimerSeenCb = () => dispatch(updateProfile({ disclaimerSeen: true }));

    return (
        <>
            {children}
            {showDisclaimer && <Disclaimer closeCb={disclaimerSeenCb} />}
        </>
    );
});

export default Onboarding;
