import { useTranslation } from 'react-i18next';
import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import LoyaltyCongrats from './loyaltyCongrats';
import LoyaltyInsulinModeUsage from './LoyaltyInsulinModeUsage';

import { RootState } from '../../../store/store';
import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';

function LoyaltyFrame({
    scrollActive2,
    cbLoyaltyCongrats,
    cbLoyaltyInsulin,
}: {
    scrollActive2: any;
    cbLoyaltyCongrats?: any;
    cbLoyaltyInsulin?: any;
}) {
    const { t } = useTranslation();

    const op5Progress = useSelector((state: RootState) => state.learningMaterial.op5Progress);
    const profileData = useSelector((state: RootState) => state.profile.data);
    const currentInsulinUsage = useSelector((state: RootState) => state.dashboard.currentInsulinUsage);

    // Skip both cards if there's no content to show
    return !(op5Progress?.data?.weekNumber || currentInsulinUsage?.dailyUsage?.insulinRatio) ? null : (
        <div className={styleGuide.insights} key="patternLoyaltyOverall">
            <div className={clsx(styleGuide.solidBg, styleGuide.header)}>
                <div className={clsx(styleGuide.line)}></div>
                <div
                    className={clsx(
                        styleGuide.title,
                        styleGeneral.toCapitalize,
                        scrollActive2 && styleGeneral.inScroll2
                    )}
                >
                    {t('loyalty.headerA')}
                    <sup>&#174;</sup>&nbsp;{t('loyalty.headerB')}
                </div>
            </div>
            {!profileData?.isOnboarded && <LoyaltyCongrats cbLoyaltyCongrats={cbLoyaltyCongrats} />}
            <LoyaltyInsulinModeUsage cbLoyaltyInsulin={cbLoyaltyInsulin} />
        </div>
    );
}

export default LoyaltyFrame;
