import { createSlice } from '@reduxjs/toolkit';

import { insightsInitialState } from './insights.initialState';
import {
    fetchInsightEventBoluses,
    fetchInsightEventModes,
    fetchInsightEventReadings,
    sendFeedback,
} from './insights.thunks';
import insightsReducers from './insights.reducers';
import restoreStateFromLocalStorageFulfilled from './extraReducers/restoreStateFromLocalStorage/fulfilled.extraReducer';
import fetchInsightEventBolusesPending from './extraReducers/fetchInsightEventBoluses/pending.extraReducer';
import fetchInsightEventBolusesFulfilled from './extraReducers/fetchInsightEventBoluses/fulfilled.extraReducer';
import fetchInsightEventModesFulfilled from './extraReducers/fetchInsightEventModes/fulfilled.extraReducer';
import fetchInsightEventModesPending from './extraReducers/fetchInsightEventModes/pending.extraReducer';
import fetchInsightEventReadingsPending from './extraReducers/fetchInsightEventReadings/pending.extraReducer';
import fetchInsightEventReadingsFulfilled from './extraReducers/fetchInsightEventReadings/fulfilled.extraReducer';
import sendFeedbackPending from './extraReducers/sendFeedback/pending.extraReducer';
import sendFeedbackFulfilled from './extraReducers/sendFeedback/fulfilled.extraReducer';

import { resetStore, restoreStateFromLocalStorage } from '../root.actions';

const insightsSlice = createSlice({
    name: 'insights',
    initialState: insightsInitialState,
    reducers: insightsReducers,
    extraReducers: (builder) => {
        builder
            .addCase(resetStore, () => insightsInitialState)
            .addCase(restoreStateFromLocalStorage, restoreStateFromLocalStorageFulfilled)
            .addCase(fetchInsightEventBoluses.pending, fetchInsightEventBolusesPending)
            .addCase(fetchInsightEventBoluses.fulfilled, fetchInsightEventBolusesFulfilled)
            .addCase(fetchInsightEventModes.pending, fetchInsightEventModesPending)
            .addCase(fetchInsightEventModes.fulfilled, fetchInsightEventModesFulfilled)
            .addCase(fetchInsightEventReadings.pending, fetchInsightEventReadingsPending)
            .addCase(fetchInsightEventReadings.fulfilled, fetchInsightEventReadingsFulfilled)
            .addCase(sendFeedback.pending, sendFeedbackPending)
            .addCase(sendFeedback.fulfilled, sendFeedbackFulfilled);
    },
});

export const {
    actions: {
        setContributingEventMetaData,
        setInsightId,
        setReadingsInfo,
        resetCachedInsights,
        resetCurrentReadingCombo,
        setInsightsData,
        resetInsightIds,
    },
} = insightsSlice;

export default insightsSlice.reducer;
