import { IAppState } from '../../app.types';
import ConstantsHelper from '../../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../../helpers/UtilityHelper';
import AnI18NextLibHelper from '../../../../helpers/AnI18NextLibHelper';

const fetchProfileFulfilled = (state: IAppState, { payload }: any) => {
    const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchProfile);

    if (callKey) {
        delete state.activeHttpCalls[callKey];
    }

    state.errors[callKey] = payload.id
        ? {
              level: ConstantsHelper.ErrorLevels.success,
              message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
              title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfileGet'),
          }
        : {
              level: ConstantsHelper.ErrorLevels.info,
              message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfileNotFound'),
              title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfileGet'),
          };

    state.loadingSemaphore--;
};

export default fetchProfileFulfilled;
