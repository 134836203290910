import { IInsightsState } from '../../insights.types';

const restoreStateFromLocalStorageFulfilled = (state: IInsightsState, action: any) => {
    state.contributingEventBeg = action.payload.contributingEventBeg;
    state.contributingEventEnd = action.payload.contributingEventEnd;
    state.contributingEventId = action.payload.contributingEventId;
    state.insightId = action.payload.insightId;
};

export default restoreStateFromLocalStorageFulfilled;
