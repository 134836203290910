import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';

import { errorActionCreator } from '../store/app/app.slice';
import { RootState } from '../store/store';
import { IError } from '../store/app/app.types';
import BtnPrimary from '../components/common/btnPrimary';
import styleGeneral from '../styles/general.module.scss';
import styleGuide from '../styles/styleGuide.module.scss';
import SystemHelper from '../helpers/SystemHelper';
import UiHelper from '../helpers/UiHelper';
import ConstantsHelper from '../helpers/ConstantsHelper';

function Error() {
    const dispatch = useDispatch();

    const anI18Nextlib = useSelector((state: RootState) => state.app.anI18Nextlib);

    const translate = (key: string, subs?: any) => UiHelper.Translate(anI18Nextlib, key, subs);

    useEffect(() => {
        SystemHelper.AppInsightsEvent(anI18Nextlib, 'Welcome()', 'Mounted');
        dispatch(
            errorActionCreator({
                key: ConstantsHelper.httpCallAndErrorKeys.general,
                error: {
                    level: ConstantsHelper.ErrorLevels.error,
                    message: `${translate('notFound.alt')}!`,
                    silent: true,
                    title: '404',
                } as IError,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styleGeneral.body}>
            <div className={styleGeneral.verticalSpacer}>
                <div className={styleGuide.errorPage}>
                    <div className={styleGuide.title}>404 {translate('notFound.alt')}!</div>
                    <BtnPrimary
                        caption={translate('goToHomeScreen')}
                        cbClickFn={() => (window.location.href = '/')}
                        focus={true}
                    />
                </div>
            </div>
        </div>
    );
}

export default Error;
