import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { Patient } from 'src/model/patient';

import { ICallback } from '../../../types';
import { RootState } from '../../../store/store';
import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import SmsNumberInput from '../SmsNumberInput';

function ConfirmSmsNumber({
    smsNumber,
    closeCb,
    resendCb,
    confirmedCb,
}: {
    smsNumber: string;
    closeCb: ICallback<void>;
    resendCb: ICallback<void>;
    confirmedCb: ICallback<void>;
}) {
    const { t } = useTranslation();

    const profileData = useSelector((state: RootState) => state.profile.data);

    useEffect(() => {
        if (
            profileData.smsNumberStatus === Patient.SmsNumberStatusEnum.Out ||
            profileData.smsNumberStatus === Patient.SmsNumberStatusEnum.In
        ) {
            confirmedCb();
        }
    }, [profileData.smsNumberStatus, confirmedCb]);

    const handleBackClick = () => {
        closeCb();
    };

    const handleResendClick = () => {
        resendCb();
    };

    return (
        <div className={clsx(styleGeneral.body, styleGeneral.whiteBackground)}>
            <div className={clsx(styleGuide.verifySmsNumber)}>
                <div className={styleGuide.title}>{t('confirmSmsNumber.header')}</div>
                <div className={styleGuide.paragraph}>{t('confirmSmsNumber.subtitle')}</div>
                <SmsNumberInput value={smsNumber} disabled />
                <div className={styleGuide.paragraph}>
                    {t('confirmSmsNumber.resend')}{' '}
                    <Link
                        className={clsx(styleGeneral.link, styleGeneral.link_fontWeightRegular)}
                        onClick={handleResendClick}
                        to="#"
                    >
                        {t('confirmSmsNumber.resendLink')}
                    </Link>
                </div>
                <div className={styleGuide.paragraph}>{t('confirmSmsNumber.backInstructions')}</div>
                <div className={styleGuide.buttons}>
                    <Link
                        data-testid="confirm-sms-number__back-button"
                        className={clsx(styleGuide.button, styleGuide.secondary)}
                        onClick={handleBackClick}
                        to="#"
                    >
                        {t('confirmSmsNumber.back')}
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default ConfirmSmsNumber;
