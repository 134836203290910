import { IAuthenticationState } from './authentication.types';

export const authenticationInitialState: IAuthenticationState = {
    oktaData: {
        access: undefined,
        aud: undefined,
        isUserDefined: undefined,
        expires: undefined,
        id: undefined,
        userName: undefined,
    },
};
