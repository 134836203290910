import ConstantsHelper from './ConstantsHelper';
import UtilityHelper from './UtilityHelper';

import { ILocalStorageValues } from '../store/root.types';
import { appInitialState } from '../store/app/app.initialState';
import { uiInitialState } from '../store/ui/ui.slice';
import { dashboardInitialState } from '../store/dashboard/dashboard.initialState';
import { insightsInitialState } from '../store/insights/insights.initialState';

export const convertBinaryStringToBoolean = (binaryStringValue: string): boolean => binaryStringValue === '1';
export const convertBooleanToBinaryString = (booleanValue: boolean): string => (booleanValue ? '1' : '0');

export const getValuesFromLocalStorage = (): ILocalStorageValues => {
    let values: ILocalStorageValues = {
        calendarDate: appInitialState.calendarDate,
        contributingEventBeg: insightsInitialState.contributingEventBeg,
        contributingEventEnd: insightsInitialState.contributingEventEnd,
        contributingEventId: insightsInitialState.contributingEventId,
        dashboardBeg: dashboardInitialState.dashboardBeg,
        dashboardEnd: dashboardInitialState.dashboardEnd,
        dashboardHasReferrer: dashboardInitialState.dashboardHasReferrer,
        idTag: appInitialState.idTag,
        insightId: insightsInitialState.insightId,
        showLegend: uiInitialState.showLegend,
    };

    Object.keys(ConstantsHelper.IntersessionKeys).forEach((key) => {
        const value = UtilityHelper.SettingGet(key);

        switch (key) {
            case ConstantsHelper.IntersessionKeys.dashboardHasReferrer:
                values = {
                    ...values,
                    dashboardHasReferrer: convertBinaryStringToBoolean(value),
                };

                break;
            case ConstantsHelper.IntersessionKeys.idTag:
                if (value?.length > 0) {
                    values = {
                        ...values,
                        idTag: value,
                    };
                }

                break;
            case ConstantsHelper.IntersessionKeys.showLegend:
                values = {
                    ...values,
                    showLegend: UtilityHelper.IsNull(value) || convertBinaryStringToBoolean(value),
                };

                break;
            default:
                if (key !== ConstantsHelper.IntersessionKeys.signOutCanProceed) {
                    values = {
                        ...values,
                        [key]: value,
                    };
                }
        }
    });

    return values;
};

export const setValuesFromStateInLocalStorage = (stateValues: Partial<ILocalStorageValues>) => {
    Object.entries(stateValues).forEach(([key, value]) => {
        const newValue: string =
            key === ConstantsHelper.IntersessionKeys.dashboardHasReferrer ||
            key === ConstantsHelper.IntersessionKeys.showLegend
                ? convertBooleanToBinaryString(value as boolean)
                : (value as string);

        UtilityHelper.SettingSet(key, newValue);
    });
};
