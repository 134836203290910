import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import rootReducer from './root.reducer';
import resetMiddleware from './reset.middleware';
import dashboardMetaDataMiddleware from './dashboardMetaData.middleware';

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(resetMiddleware).concat(dashboardMetaDataMiddleware),
});

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
