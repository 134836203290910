import { IAppState } from '../../app.types';
import ConstantsHelper from '../../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../../helpers/UtilityHelper';
import UiHelper from '../../../../helpers/UiHelper';
import AnI18NextLibHelper from '../../../../helpers/AnI18NextLibHelper';

const fetchBackEndIdRejected = (state: IAppState) => {
    const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchBackEndId);
    state.errors[callKey] = UiHelper.CreateErrorMessage({
        level: ConstantsHelper.ErrorLevels.error,
        message: `${AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.invalidResponse')}`,
        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.backEndId'),
    });
    state.loadingSemaphore--;
};

export default fetchBackEndIdRejected;
