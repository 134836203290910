import { useTranslation } from 'react-i18next';
import React from 'react';
import clsx from 'clsx';

import { ICallback } from '../../../types';
import BtnPrimary from '../btnPrimary';
import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import iconOpenArmBoy from '../../../assets/images/boy-open-arm.svg';
import UiHelper from '../../../helpers/UiHelper';
import SystemHelper from '../../../helpers/SystemHelper';

function NotFound({
    clearErrorCb,
}: Readonly<{
    clearErrorCb: ICallback<void>;
}>) {
    const { t } = useTranslation();
    const browserInfo = SystemHelper.GetBrowserInfo();
    const isProduction = SystemHelper.IsProd();

    return (
        <>
            <div
                className={clsx(
                    styleGeneral.layerTopmostLess1,
                    UiHelper.GetBannerSpacingClasses(
                        isProduction,
                        browserInfo.supported,
                        styleGuide.modalBackdrop,
                        styleGuide.modalBackdropOneBanner,
                        styleGuide.modalBackdropTwoBanners
                    )
                )}
            />
            <div
                className={clsx(
                    styleGeneral.errorPage,
                    styleGeneral.errorPageTest,
                    styleGeneral.layerTopmostLess1,
                    styleGuide.notFound,
                    'errorPageAnimation'
                )}
            >
                <div className={styleGuide.image}>
                    <img src={iconOpenArmBoy} alt={t('notFound.alt')} />
                </div>
                <div className={styleGuide.ohNo}>{t('notFound.title')}</div>
                <div className={styleGuide.cantFind}>{t('notFound.subTitle')}</div>
                <div>
                    <BtnPrimary caption={t('notFound.buttonText')} cbClickFn={clearErrorCb} />
                </div>
            </div>
        </>
    );
}

export default NotFound;
