import { IInsightsState } from '../../insights.types';
import EventHelper from '../../../../helpers/EventHelper';

const fetchInsightEventReadingsFulfilled = (state: IInsightsState, action: any) => {
    state.currentReadingCombo = EventHelper.OrderEventReadings(
        action.payload.id,
        state.cachedInsights[action.payload.id2],
        state.readingsInfo.fillGaps,
        {
            ...state.currentReadingCombo,
            ...action.payload?.data,
        }
    );
};

export default fetchInsightEventReadingsFulfilled;
