import { IAppState } from '../../app.types';
import ConstantsHelper from '../../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../../helpers/UtilityHelper';
import UiHelper from '../../../../helpers/UiHelper';
import AnI18NextLibHelper from '../../../../helpers/AnI18NextLibHelper';

const sendFeedbackRejected = (state: IAppState, action: any) => {
    const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.sendFeedback, [
        action.meta?.arg?.insightId,
        action.meta?.arg?.feedbackValue,
        action.meta?.arg?.insightIdParent,
    ]);
    const message =
        action?.payload?.message ??
        action?.error?.message ??
        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error');
    state.message = message;
    state.errors[callKey] = UiHelper.CreateErrorMessage({
        level: ConstantsHelper.ErrorLevels.error,
        message,
        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.feedback'),
    });
    state.loadingSemaphore--;
};

export default sendFeedbackRejected;
