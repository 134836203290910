import { createSlice } from '@reduxjs/toolkit';

import { fetchProfile } from './profile.thunks';
import { profileInitialState } from './profile.initialState';
import profileReducers from './profile.reducers';
import fetchProfileFulfilled from './extraReducers/fetchProfile/fulfilled.extraReducer';
import fetchProfileRejected from './extraReducers/fetchProfile/rejected.extraReducer';
import fetchLearningMaterialOp5ProgressFulfilled from './extraReducers/fetchLearningMaterialOp5Progress/fulfilled.extraReducer';

import { resetStore } from '../root.actions';
import { fetchLearningMaterialOp5Progress } from '../learningMaterial/learningMaterial.thunks';

const profileSlice = createSlice({
    name: 'profile',
    initialState: profileInitialState,
    reducers: profileReducers,
    extraReducers: (builder) => {
        builder
            .addCase(resetStore, () => profileInitialState)
            .addCase(fetchProfile.fulfilled, fetchProfileFulfilled)
            .addCase(fetchProfile.rejected, fetchProfileRejected)
            .addCase(fetchLearningMaterialOp5Progress.fulfilled, fetchLearningMaterialOp5ProgressFulfilled);
    },
});

export const {
    actions: { updatePatient },
} = profileSlice;

export default profileSlice.reducer;
