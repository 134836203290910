import { IDashboardState } from '../../dashboard.types';

const fetchDashboardInsulinUsageTrendFulfilled = (state: IDashboardState, action: any) => {
    if (action.payload) {
        state.currentInsulinUsage = {
            ...state.currentInsulinUsage,
            ...action.payload.data.item,
        };
    }
};

export default fetchDashboardInsulinUsageTrendFulfilled;
