import { LearningMaterialProgress } from '../../../../model/learningMaterialProgress';
import { IProfileState } from '../../profile.types';

const fetchLearningMaterialOp5ProgressFulfilled = (state: IProfileState, action: any) => {
    if (action.payload) {
        const op5Progress: LearningMaterialProgress = {
            ...action.payload.data.item.progress,
        };

        state.data.isOnboarded = (op5Progress.weekNumber ?? -1) === -1;
    }
};

export default fetchLearningMaterialOp5ProgressFulfilled;
